<template>
    <b-modal id="report-dashboard-modal"
             centered
             hide-footer
             size="lg"
             :no-close-on-esc="false"
             :no-close-on-backdrop="false">
        <div class="d-flex justify-content-end align-items-center flex-nowrap mr-2">
            <b-button variant="primary" pill @click="downloadPDF">
                <feather-icon icon="DownloadIcon" class="mr-50" />
                <span class="align-middle">Download PDF</span>
            </b-button>
        </div>
        <div id="report">
            <div class="container">
                <b-row class="invoice-preview">
                    <b-col md="12">
                        <div>
                            <b-row>
                                <table style="width:100%;">
                                    <tr>
                                        <!--<td style="padding-left:60px;">
                                            <img :src='image'
                                                 alt="logo"
                                                 width="100%"
                                                 />
                                        </td>-->
                                        <td style="text-align: center; width:100%;">

                                            <label style="font-weight: bolder; font-size: 25px; ">
                                                <u>FACILITATION PORTAL</u>
                                            </label>
                                        </td>
                                    </tr>
                                </table>
                            </b-row>

                            <b-row>
                                <table>
                                    <tr>
                                        <td>&nbsp;</td>

                                    </tr>
                                </table>
                            </b-row>

                            <b-row>
                                <table style="width:100%; border: 1px solid black;">
                                    <tr>
                                        <td style="text-align:center; font-weight: bolder; font-size: 16px; padding:3px 0 0 2px ; background-color:#d5f1d5;">
                                            REGISTRATION SUMMARY DETAIL
                                        </td>
                                    </tr>
                                </table>
                            </b-row>

                            <b-row>
                                <table style="width:100%; border: 1px solid black;">
                                    <tr style="font-weight: bolder; font-size: 14px; padding:16% 0 0 0; background-color: ;">
                                        <td style="text-align:center; background-color:#d5f1d5;">
                                            <label style="">
                                                SERVING OFFICERS
                                            </label>
                                        </td>
                                        <td style=" text-align:center; background-color:#d5f1d5;">
                                            <label style="">
                                                RETIRED OFFICERS
                                            </label>
                                        </td>
                                        <td style="text-align:center; background-color:#d5f1d5;">
                                            <label style="">
                                                SHUHADA FAMILY
                                            </label>
                                        </td>
                                        <td style="text-align:center; font-size: 20px; background-color:red; color:white;" rowspan="4">
                                            <label style="">
                                                TOTAL USERS {{total_user}}
                                            </label>
                                        </td>
                                    </tr>

                                    <tr style="font-size: 15px; padding-top:12%;">

                                        <td style="text-align:center; background-color:#d5f1d5;">
                                            <label style="">
                                                {{total_serving_user}}
                                            </label>
                                        </td>
                                        <td style="text-align:center; background-color:#d5f1d5;">
                                            <label style="">
                                                {{total_retired_user}}
                                            </label>
                                        </td>
                                        <td style="text-align:center; background-color:#d5f1d5;">
                                            <label style="">
                                                {{total_civil_user}}
                                            </label>
                                        </td>
                                    </tr>

                                    <tr style="font-weight: bolder; font-size: 14px;  border: 1px solid black; padding:16% 0 0 0; background-color: ;">

                                        <td style="text-align:center; background-color:#f7e7cb;">
                                            <label style="">
                                                APPROVED
                                            </label>
                                        </td>
                                        <td style=" text-align:center; background-color:#d5f1d5;">
                                            <label style="">
                                                REJECTED
                                            </label>
                                        </td>
                                        <td style="text-align:center; background-color:#d5f1d5;">
                                            <label style="">
                                                PENDING
                                            </label>
                                        </td>
                                    </tr>
                                    <tr style="font-size: 15px; padding-top:12%;">
                                        <td style="text-align:center; background-color:#f7e7cb; font-weight: bolder;">
                                            <label style="">
                                                {{total_approved}}
                                            </label>
                                        </td>
                                        <td style="text-align:center; background-color:#d5f1d5;">
                                            <label style="">
                                                {{total_rejected}}
                                            </label>
                                        </td>
                                        <td style="text-align:center; background-color:#d5f1d5;">
                                            <label style="">
                                                {{total_pending}}
                                            </label>
                                        </td>
                                    </tr>

                                </table>
                            </b-row>
                            <!-- <b-row>
                                <table  style="width:100%; border: 1px solid black;">

                                    <tr style="font-weight: bolder; font-size: 14px;  border: 1px solid black; padding:16% 0 0 0; background-color: #f3f2f7;">

                                        <td style="text-align:center; ">
                                            <label style="">
                                                Approved
                                            </label>
                                        </td>
                                        <td style=" text-align:center;">
                                            <label style="">
                                                Rejected
                                            </label>
                                        </td>
                                        <td style="text-align:center;">
                                            <label style="">
                                                Pending
                                            </label>
                                        </td>
                                        <td style="text-align:center; ">
                                            <label style="">
                                                Number of Calls
                                            </label>
                                        </td>
                                    </tr>
                                    <tr style="font-size: 13px; padding-top:12%;">
                                        <td style="text-align:center;">
                                            <label style="">
                                                {{total_approved}}
                                            </label>
                                        </td>
                                        <td style="text-align:center;">
                                            <label style="">
                                                {{total_rejected}}
                                            </label>
                                        </td>
                                        <td style="text-align:center;">
                                            <label style="">
                                                {{total_pending}}
                                            </label>
                                        </td>
                                        <td style="text-align:center;">
                                            <label style="">
                                            {{total_call_count}}
                                            </label>
                                        </td>
                                    </tr>
                                </table>
                            </b-row>  -->
                            <!-- <b-row>
                                <table  style="width:100%; border: 1px solid black;">

                                    <tr style="font-weight: bolder; font-size: 14px;  border: 1px solid black; padding:16% 0 0 0; background-color: #f3f2f7;">

                                        <td style="text-align:center; ">
                                            <label style="">
                                                Approved
                                            </label>
                                        </td>
                                        <td style=" text-align:center;">
                                            <label style="">
                                                Rejected
                                            </label>
                                        </td>
                                        <td style="text-align:center;">
                                            <label style="">
                                                Pending
                                            </label>
                                        </td>
                                        <td style="text-align:center; ">
                                            <label style="">
                                                Number of Calls
                                            </label>
                                        </td>

                                    </tr>
                                    <tr style="font-size: 13px; padding-top:12%;">

                                        <td style="text-align:center;">
                                            <label style="">
                                                {{total_approved}}
                                            </label>
                                        </td>
                                        <td style="text-align:center;">
                                            <label style="">
                                                {{total_rejected}}
                                            </label>
                                        </td>
                                        <td style="text-align:center;">
                                            <label style="">
                                                {{total_pending}}
                                            </label>
                                        </td>
                                        <td style="text-align:center;">
                                            <label style="">
                                            {{total_call_count}}
                                            </label>
                                        </td>
                                    </tr>

                                </table>
                            </b-row>  -->
                            <b-row>
                                <table style="width:100%; border: 1px solid black;">
                                    <tr>
                                        <td style="text-align:center; font-weight: bolder; font-size: 16px; padding:3px 0 0 2px ; background-color:#bdc8f9;">
                                            APPROVAL STATE
                                        </td>
                                    </tr>
                                </table>
                            </b-row>
                            <b-row>
                                <table class="table" style="border: 1px solid black;">
                                    <thead style="font-weight: bolder;  font-size: 14px;">
                                        <tr>
                                            <th scope="col" style="padding-top: 2px; background-color:#bdc8f9;">
                                                <p>DATE</p>
                                            </th>
                                            <th scope="col" style="padding-top: 2px; background-color:red; color:white; width:13%;">
                                                <p>TOTAL</p>
                                            </th>
                                            <th scope="col" style="padding-top: 2px; background-color:#f7e7cb;">
                                                <p>SERVING OFFICERS</p>
                                            </th>
                                            <th scope="col" style="padding-top: 2px; background-color:#bdc8f9;">
                                                <p>RETIRED OFFICERS</p>
                                            </th>
                                            <th scope="col" style="padding-top: 2px; background-color:rgb(213 216 229);">
                                                <p>SHUHADA FAMILY</p>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in table_data_last_weeek" :key="item.id">
                                            <td style="font-size: 15px; padding-top: 2px">
                                                <p style="padding: 2px; text-align: center;background-color:#bdc8f9;">
                                                    {{ item.Date }}
                                                </p>
                                            </td>
                                            <td style="font-size: 15px; padding-top: 2px">
                                                <p style="padding: 2px; text-align: center; background-color:red; color:white;">
                                                    {{ item.Total }}
                                                </p>
                                            </td>
                                            <td style="font-size: 15px; padding-top: 2px">
                                                <p style="padding: 2px; text-align: center; background-color:#f7e7cb;">
                                                    {{ item.Serving }}
                                                </p>
                                            </td>
                                            <td style="font-size: 15px; padding-top: 2px">
                                                <p style="padding: 2px; text-align: center; background-color:#bdc8f9;">
                                                    {{ item.Retired }}
                                                </p>
                                            </td>
                                            <td style="font-size: 15px; padding-top: 2px">
                                                <p style="padding: 2px; text-align: center; background-color:rgb(213 216 229);">
                                                    {{ item.ShuhadaFamily }}
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-row>
                            <b-row>
                                <table style="width:100%; border: 1px solid black;">
                                    <tr>
                                        <td style="text-align:center; font-weight:bolder; font-size: 16px; padding-top:3px;">
                                            TODAY'S STATISTICS
                                        </td>
                                    </tr>
                                </table>
                            </b-row>
                            <b-row>
                                <table style="width:100%; border: 1px solid black;">

                                    <tr style="font-weight: bolder; font-size: 14px; padding:16% 0 0 0; background-color: #f3f2f7;">
                                        <td style="text-align:center; ">
                                            <label style="">
                                                REGISTERED USERS
                                            </label>
                                        </td>
                                        <td style="text-align:center; ">
                                            <label style="">
                                                SERVING OFFICERS
                                            </label>
                                        </td>
                                        <td style=" text-align:center;">
                                            <label style="">
                                                RETIRED OFFICERS
                                            </label>
                                        </td>
                                        <td style="text-align:center;">
                                            <label style="">
                                                SHUHADA FAMILY
                                            </label>
                                        </td>

                                    </tr>
                                    <tr style="font-size: 15px; padding-top:12%;">
                                        <td style="text-align:center;">
                                            <label style="">
                                                {{today_user}}
                                            </label>
                                        </td>
                                        <td style="text-align:center;">
                                            <label style="">
                                                {{today_serving}}
                                            </label>
                                        </td>
                                        <td style="text-align:center;">
                                            <label style="">
                                                {{today_retired}}
                                            </label>
                                        </td>
                                        <td style="text-align:center;">
                                            <label style="">
                                                {{today_civil}}
                                            </label>
                                        </td>

                                    </tr>
                                </table>
                            </b-row>
                            <b-row>
                                <table style="width:100%; border: 1px solid black;">

                                    <tr style="font-weight: bolder; font-size: 14px;  border: 1px solid black; padding:16% 0 0 0; background-color: #f3f2f7;">

                                        <td style="text-align:center; ">
                                            <label style="">
                                                APPROVED
                                            </label>
                                        </td>
                                        <td style=" text-align:center;">
                                            <label style="">
                                                REJECTED
                                            </label>
                                        </td>
                                        <td style="text-align:center;">
                                            <label style="">
                                                PENDING
                                            </label>
                                        </td>
                                        <td style="text-align:center; ">
                                            <label style="">
                                                NUMBER OF CALLS
                                            </label>
                                        </td>

                                    </tr>
                                    <tr style="font-size: 15px; padding-top:12%;">

                                        <td style="text-align:center;">
                                            <label style="">
                                                {{today_approved}}
                                            </label>
                                        </td>
                                        <td style="text-align:center;">
                                            <label style="">
                                                {{today_rejected}}
                                            </label>
                                        </td>
                                        <td style="text-align:center;">
                                            <label style="">
                                                {{today_pending}}
                                            </label>
                                        </td>
                                        <td style="text-align:center;">
                                            <label style="">
                                                {{today_call_count}}
                                            </label>
                                        </td>

                                    </tr>
                                </table>
                            </b-row>
                            <b-row>
                                <table style="width:100%; border: 1px solid black;">
                                    <tr>
                                        <td style="text-align:center; font-weight: bolder; font-size: 16px; padding-top:3px; background-color:#eded96;">
                                            APPLICATION SUMMARY DETAIL
                                        </td>
                                    </tr>
                                </table>
                            </b-row>
                            <b-row>
                                <table class="table" style="border: 1px solid black;">
                                    <thead style="font-weight: bolder;  font-size: 14px;">
                                        <tr style="background-color: #f3f2f7;">
                                            <th scope="col" style="padding-top: 2px; background-color:#eded96;">
                                                <p>APPLICATIONS</p>
                                            </th>
                                            <th scope="col" style="padding-top: 2px; background-color:#bdc8f9;">
                                                <p>TOTAL</p>
                                            </th>
                                            <th scope="col" style="padding-top: 2px;background-color:#f7e7cb;">
                                                <p>UNDER PROCESS</p>
                                            </th>
                                            <th scope="col" style="padding-top: 2px;background-color:#f7e7cb;">
                                                <p>PROCESSED</p>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="font-size: 14px;  padding-top: 2px">
                                                <p style="padding: 2px; text-align: center; font-weight: bolder; background-color:#eded96;">
                                                    Petition
                                                </p>
                                            </td>
                                            <td style="font-size: 15px;  padding-top: 2px">
                                                <p style="padding: 2px; text-align: center; background-color:#bdc8f9;">
                                                    {{total_petition}}
                                                </p>
                                            </td>
                                            <td style="font-size: 15px;  padding-top: 2px">
                                                <p style="padding: 2px; text-align: center; background-color:#f7e7cb;">
                                                    {{total_pending_petition}}
                                                </p>
                                            </td>
                                            <td style="font-size: 15px;  padding-top: 2px">
                                                <p style="padding: 2px; text-align: center;  background-color:#8ceb8c;">
                                                    {{total_processed_petition}}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-size: 14px;  padding-top: 2px">
                                                <p style="padding: 2px; text-align: center; font-weight: bolder; background-color:#eded96;">
                                                    NOCs
                                                </p>
                                            </td>
                                            <td style="font-size: 15px;  padding-top: 2px">
                                                <p style="padding: 2px; text-align: center; background-color:#bdc8f9;">
                                                    {{total_noc}}
                                                </p>
                                            </td>
                                            <td style="font-size: 15px;  padding-top: 2px">
                                                <p style="padding: 2px; text-align: center; background-color:#f7e7cb;">
                                                    {{total_pending_noc}}
                                                </p>
                                            </td>
                                            <td style="font-size: 15px;  padding-top: 2px">
                                                <p style="padding: 2px; text-align: center;  background-color:#8ceb8c;">
                                                    {{total_processed_noc}}
                                                </p>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </b-row>
                            <b-row>
                                <table style="width:100%; border: 1px solid black;">
                                    <tr>
                                        <td style="font-size: 14px; padding-top: 2px; width:25%;">
                                            <p style="padding: 2px;  text-align: center; font-weight: bolder; background-color:#eded96;">
                                                Guest Room Booking
                                            </p>
                                        </td>
                                        <td style="font-size: 15px;  padding-top: 2px; width:25%;">
                                            <p style="padding: 2px;  text-align: center; background-color:#bdc8f9;">
                                                {{total_booking}}
                                            </p>
                                        </td>
                                        <td style="font-size: 15px;  padding-top: 2px ;">
                                            <p style="padding-top: 5px;  text-align: center; font-weight:bolder; background-color:#8ceb8c;">
                                                CONFIRMED = {{total_confirmed_booking}}
                                            </p>
                                        </td>
                                    </tr>
                                </table>
                            </b-row>

                            <b-row>
                                <table style="width:100%; border: 1px solid black;">
                                    <tr>
                                        <td style="text-align:center; font-weight: bolder; font-size: 16px; padding-top:3px;">
                                            TODAY'S STATISTICS
                                        </td>
                                    </tr>
                                </table>
                            </b-row>

                            <b-row>
                                <table style="width:100%; border: 1px solid black;">
                                    <tr>
                                        <td style="font-weight: bolder; font-size: 14px; padding:3px 0 0 2px ; width:33.5%; background-color: #f3f2f7;">
                                            Petition
                                        </td>
                                        <td style="font-size: 13px; padding:3px 0 0 2px ;">
                                            <label>RECEIVED = {{today_petition}}</label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;   <label>PROCESSED = {{today_processed_petition}}</label>
                                        </td>
                                    </tr>
                                </table>
                            </b-row>
                            <b-row>
                                <table style="width:100%; border: 1px solid black;">
                                    <tr>
                                        <td style="font-weight: bolder; font-size: 14px; padding:3px 0 0 2px ; width:33.5%; background-color: #f3f2f7;">
                                            NOCs
                                        </td>
                                        <td style="font-size: 13px; padding:3px 0 0 2px ;">
                                            <label>RECEIVED = {{today_noc}}</label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;   <label>PROCESSED = {{today_processed_noc}}</label>
                                        </td>
                                    </tr>
                                </table>
                            </b-row>
                            <b-row>
                                <table style="width:100%; border: 1px solid black;">
                                    <tr>
                                        <td style="font-weight: bolder; font-size: 14px; padding:3px 0 0 2px ; width:33.5%; background-color: #f3f2f7;">
                                            Guest Room Booking
                                        </td>
                                        <td style="font-size: 13px; padding:3px 0 0 2px ;">
                                            <label>CONFIRMED = {{today_confirmed_booking}}</label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <label> CANCELLED = {{today_cancelled_booking}}</label>
                                        </td>
                                    </tr>
                                </table>
                            </b-row>
                            <b-row>
                                <table style="width:100%;">
                                    <tr>
                                        <td style="text-align: center;font-size: 14px; padding-top:3px;">
                                            This system-generated report was generated at <label style="font-weight:bolder;">{{formattedDate}}</label> by <label style="font-weight:bolder;">{{getUser.name}}</label>, ID <label style="font-weight:bolder;">{{getUser.id}}</label>.
                                            <!-- by <label style="font-weight:bolder;" >admin</label>  -->
                                        </td>
                                    </tr>
                                </table>
                            </b-row>
                        </div>

                    </b-col>

                </b-row>

            </div>
            <br>
        </div>
        <b-overlay :show="show"
                   spinner-variant="primary"
                   no-wrap />
    </b-modal>

</template>

<script>
    // import html2pdf from "html2pdf.js";
    import { mapActions, mapGetters } from "vuex";
    import util from "@/util.js";
    export default {
        components: {
            // mapActions
        },
        mixins: [util],
        props: {
            report_data: Object,
        },
        data() {
            return {
                show: false,
                total_user: null,
                total_approved: null,
                total_rejected: null,
                total_pending: null,
                total_serving_user: null,
                total_retired_user: null,
                total_civil_user: null,
                total_call_count: null,
                today_serving: null,
                total_summary_line: null,
                summary_line_dates: null,
                serving_summary_line: null,
                retired_summary_line: null,
                civil_summary_line: null,
                summary_line: null,
                today_user: null,
                today_approved: null,
                today_rejected: null,
                today_pending: null,
                today_retired: null,
                today_civil: null,
                today_call_count: null,
                total_booking: null,
                total_pending_booking: null,
                total_confirmed_booking: null,
                total_petition: null,
                total_pending_petition: null,
                total_processing_petition: null,
                total_noc: null,
                total_pending_noc: null,
                total_processing_noc: null,
                today_booking: null,
                today_petition: null,
                today_noc: null,
                table_data_last_weeek: [],
                total_email_count: null,
                formattedDate: null,
                image: process.env.VUE_APP_MEDIA + 'afp_logo.png',
                total_processed_petition: null,
                total_processed_noc: null,
                today_processed_petition: null,
                today_processed_noc: null,
                today_confirmed_booking: null,
                today_cancelled_booking: null,
            }
        },
        async mounted() {
            // await this.retrieveUserData();
            if (this.report_data) {
                this.total_user = this.report_data.total_user;
                this.total_approved = this.report_data.total_summary[0];
                this.total_rejected = this.report_data.total_summary[1];
                this.total_pending = this.report_data.total_summary[2];
                this.total_serving_user = this.report_data.total_serving_user;
                this.total_retired_user = this.report_data.total_retired_user;
                this.total_civil_user = this.report_data.total_civil_user;
                this.total_call_count = this.report_data.total_call_count;
                this.total_summary_line = this.report_data.total_summary_line_chart_data[0].data;
                this.serving_summary_line = this.report_data.total_summary_line_chart_data[1].data;
                this.retired_summary_line = this.report_data.total_summary_line_chart_data[2].data;
                this.civil_summary_line = this.report_data.total_summary_line_chart_data[3].data;
                this.summary_line_dates = Object.keys(this.report_data.total_summary_line_chart_data[0].data);
                this.summary_line = this.report_data.total_summary_line_chart_data;
                this.today_user = this.report_data.today_user;
                this.today_approved = this.report_data.today_approved;
                this.today_rejected = this.report_data.today_rejected;
                this.today_pending = this.report_data.today_pending;
                this.today_serving = this.report_data.today_serving;
                this.today_retired = this.report_data.today_retired;
                this.today_civil = this.report_data.today_civil;
                this.today_call_count = this.report_data.today_call_count;
                this.total_booking = this.report_data.total_booking;
                this.total_pending_booking = this.report_data.total_pending_booking;
                this.total_confirmed_booking = this.report_data.total_confirmed_booking;
                this.total_petition = this.report_data.total_petition;
                this.total_pending_petition = this.report_data.total_pending_petition;
                this.total_processing_petition = this.report_data.total_processing_petition;
                this.total_noc = this.report_data.total_noc;
                this.total_pending_noc = this.report_data.total_pending_noc;
                this.total_processing_noc = this.report_data.total_processing_noc;
                this.today_booking = this.report_data.today_booking;
                this.today_petition = this.report_data.today_petition;
                this.today_noc = this.report_data.today_noc;
                this.table_data_last_weeek = this.report_data.table_data_last_weeek;
                this.table_data_last_weeek.splice(0, 6);
                this.total_email_count = this.report_data.total_email_count;
                let currentDate = new Date().toJSON().slice(0, 10);
                this.formattedDate = new Date(currentDate).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit'
                });
                this.total_processed_petition = this.report_data.total_closed_petition;
                this.total_processed_noc = this.report_data.total_closed_noc;
                this.today_processed_petition = this.report_data.today_processed_petition;
                this.today_processed_noc = this.report_data.today_processed_noc;
                this.today_confirmed_booking = this.report_data.today_confirmed_booking;
                this.today_cancelled_booking = this.report_data.today_cancelled_booking;


                await this.startTimer();

            }

        },


        methods: {
            ...mapActions({
                getDashboardReport: "appData/getDashboardReport",
                // getCurrentUser: "appData/getCurrentUser",
            }),
            async startTimer() {
                // Show some indication that the timer has started
                console.log('Timer started');

                // Wait for 3 seconds using setTimeout
                setTimeout(async () => {
                    // After 3 seconds, call the downloadPDF function
                    await this.downloadPDF();
                }, 1000);
            },
            formatDate(date) {
                const months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
                const day = date.getDate();
                const month = months[date.getMonth()];
                const year = date.getFullYear();
                return `${day}_${month}_${year}`;
            },
            async downloadPDF() {
                try {
                    this.show = true;
                    let html = document.getElementById("report").innerHTML;
                    const res = await this.getDashboardReport({
                        html: html,
                    });
                    if (res.status === 200) {
                        this.show = false;
                    }
                    this.show = false;
                    //this.file_path = res.data.data;
                    //this.link_path = process.env.VUE_APP_MEDIA + this.file_path;
                    //window.open(this.link_path, '_blank');

                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `fp_${this.formattedDate}_report.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    this.$nextTick(() => {
                        this.$bvModal.hide("report-dashboard-modal");
                    });
                    this.$emit("modalClosed");
                } catch (error) {
                    this.show = false;
                    this.displayError(error);
                }
            },

        },

        computed: {
            ...mapGetters({
                getUser: "appData/getUser",
            }),
        }

    }
</script>

<style>
</style>
