<template>
    <div>
        <!-- card start class="bg-image shadow border-0" -->
        <div>
            <b-row>
                <b-col >
                    <vue-apex-charts type="line"
                                     height="347"
                                     :options="lineChartSimple.chartOptions"
                                     @dataPointSelection="clickHandler"
                                     :series="lineChartSimple.series" />
                </b-col>
            </b-row>
            <!--<b-row>
                <b-col>-->
            <!-- card start class="bg-image shadow border-0 mb-1"-->
            <!--<div style="display: flex; justify-content: space-between;">
                <div>
                    <h3 class="font-weight-bolder">
                        <b-img :src="require('@/assets/images/newDashboard/call.svg')"
                               alt="Medal Pic"
                               width="30px" /> &nbsp;&nbsp;
                        Number of Calls
                    </h3>
                </div>
                <div>
                    <p style="color:rgb(14, 159, 217); font-weight:bolder; font-size: 30px;">
                        {{total_call_count}}
                    </p>
                </div>
            </div>-->
            <!-- card end -->
            <!--</b-col>
            </b-row>-->
        </div>
        <!-- b-card end  -->
        <!--<b-row>
            <b-col md="5">
                <h3 style="font-weight: bold">Today's Statistics</h3>
                <br>
                <ul>
                    <li class="first">Pending</li>
                    <label>{{today_pending}}</label>
                    <li class="second">Rejected</li>
                    <label>{{today_rejected}}</label>
                    <li class="third">Approved</li>
                    <label>{{today_approved}}</label>
                </ul>
            </b-col>
            <b-col>
                <vue-apex-charts type="donut"
                                 height="350"
                                 @dataPointSelection="clickHandler"
                                 :options="donutChart.chartOptions"
                                 :series="donutChart.series" />
            </b-col>
        </b-row>-->
        <b-overlay :show="show"
                   spinner-variant="primary"
                   no-wrap />
        <view-filtered-user-modal :id="`view-today-filtered-user-modal`"
                                  :key="`view_total-${viewModalCounting}`"
                                  :users="users" />
    </div>

</template>

<script>
    import {
        BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge,
    } from 'bootstrap-vue'
    import VueApexCharts from 'vue-apexcharts'
    import { $themeColors } from '@themeConfig'
    import { mapActions, mapGetters } from "vuex";
    import util from "../../../util";
    import ViewFilteredUserModal from "./ViewFilteredUserModal.vue";

    export default {
        components: {
            ViewFilteredUserModal,
            VueApexCharts,
            BCardHeader,
            BCard,
            BBadge,
            BCardBody,
            BCardTitle,
            BCardSubTitle,
        },
        props: {
            details: Array,
            data: Array,
            total_user_last_week: Array,
        },
        mixins: [util],
        data() {
            return {
                donutChart: {
                    series: [85, 16, 50],
                    chartOptions: {
                        legend: {
                            show: true,
                            position: 'bottom',
                            fontSize: '14px',
                            fontFamily: 'Montserrat',
                        },
                        //colors: [
                        //    '#a4f8cd',
                        //    '#60f2ca',
                        //    '#2bdac7',
                        //],
                        // title: {
                        //     text: "Today's Summary",
                        //     margin: 10,
                        //     offsetX: 0,
                        //     offsetY: 0,
                        //     floating: false,
                        //     style: {
                        //         fontSize: '25px',
                        //         fontWeight: 'bolder',
                        //         color: '#535858'
                        //     },
                        // },
                        dataLabels: {
                            enabled: false,
                            formatter(val) {
                                // eslint-disable-next-line radix
                                return `${parseInt(val)}`;
                            },
                        },
                        plotOptions: {
                            pie: {
                                donut: {
                                    labels: {
                                        show: true,
                                        name: {
                                            fontSize: '2rem',
                                            fontFamily: 'Montserrat',
                                        },
                                        value: {
                                            fontSize: '1rem',
                                            fontFamily: 'Montserrat',
                                            formatter(val) {
                                                // eslint-disable-next-line radix
                                                return `${parseInt(val)}`
                                            },
                                        },
                                        total: {
                                            show: true,
                                            fontSize: '1.5rem',
                                            label: 'Total',
                                            formatter(series) {
                                                const total = series.config.series.reduce((acc, val) => acc + val, 0);
                                                return total.toString();
                                            },
                                        },
                                    },
                                },
                            },
                        },
                        labels: ['Approved', 'Rejected', 'Pending'],
                        responsive: [
                            {
                                breakpoint: 604,
                                options: {
                                    chart: {
                                        height: 380,
                                    },
                                    legend: {
                                        position: 'bottom',
                                    },
                                },
                            },
                            {
                                breakpoint: 576,
                                options: {
                                    chart: {
                                        height: 320,
                                    },
                                    plotOptions: {
                                        pie: {
                                            donut: {
                                                labels: {
                                                    show: true,
                                                    name: {
                                                        fontSize: '1.5rem',
                                                    },
                                                    value: {
                                                        fontSize: '1rem',
                                                    },
                                                    total: {
                                                        fontSize: '1.5rem',
                                                    },
                                                },
                                            },
                                        },
                                    },
                                    legend: {
                                        show: true,
                                    },
                                },
                            },
                        ],
                    },
                },
                lineChartSimple: {
                    series: [
                        {
                            name: 'Total',
                            data: [5, 40, 45, 20, 49, 60, 22, 21, 54, 62],
                            color: '#000000',
                            lineWidth: 50,
                        },
                        {
                            name: 'Serving',
                            data: [20, 40, 5, 50, 49, 60, 12, 87, 21, 34],
                            color: '#FF0000',
                        },
                        {
                            name: 'Retired',
                            data: [30, 40, 15, 50, 9, 30, 52, 43, 22, 54],
                            color: '#369000',
                        },
                        {
                            name: 'Shuhada Family',
                            data: [10, 40, 45, 60, 49, 60, 32, 65, 21, 43],
                            color: '#00A9DF',
                        },
                    ],
                    chartOptions: {
                        title: {
                            text: "Approval Statistics",
                            margin: 10,
                            offsetX: 0,
                            offsetY: 0,
                            floating: false,
                            style: {
                                fontSize: '22px',
                                fontWeight: 'bold',
                                fontFamily: 'Montserrat',
                                color: '#656467'
                            },
                        },
                        chart: {
                            zoom: {
                                enabled: false,
                            },
                            toolbar: {
                                show: true,
                            },
                        },
                        stroke: {
                            show: true,
                            curve: 'straight',
                            width: 2.5,
                        },
                        //markers: {
                        //    strokeWidth: 2,
                        //    strokeOpacity: 1,
                        //    strokeColors: [$themeColors.light],
                        //    colors: [$themeColors.warning],
                        //},
                        colors: ['#A52A2A'],
                        dataLabels: {
                            enabled: false,
                        },
                       
                        grid: {
                            xaxis: {
                                lines: {
                                    show: true,
                                },
                            },
                        },
                        tooltip: {
                            //custom(data) {
                            //    return `${'<div class="px-1 py-50"><span>'}${data.series[data.seriesIndex][data.dataPointIndex]
                            //        } Users</span></div>`
                            //},
                        },
                        xaxis: {
                            categories: [
                                '08-03-2024',
                                '09-03-2024',
                                '10-03-2024',
                                '11-03-2024',
                                '12-03-2024',
                                '13-03-2024',
                                '14-03-2024',
                                '15-03-2024',
                                '16-03-2024',
                                '17-03-2024',
                            ],
                        },
                        yaxis: {
                            // opposite: isRtl,
                        },
                    },
                },
                data_array: [],
                searching_criteria: {},
                users: null,
                show: false,
                viewModalCounting: 0,
                today_pending: 0,
                today_approved: 0,
                today_rejected: 0,
                total_call_count: 0,
            }
        },
        async created() {
            if (this.total_user_last_week) {
                this.data_array = this.details;
                //this.donutChart.series = this.data_array;
                const simplifiedData = this.total_user_last_week.map(entry => {
                    //if ("Sun" in entry.data) {
                    //    // Exclude the "Sun" entry
                    //    delete entry.data.Sun;
                    //}
                    return {
                        name: entry.name,
                        color: entry.color,
                        data: Object.values(entry.data) // Extract values without keys
                    };
                });
                this.lineChartSimple.series = simplifiedData;
                this.lineChartSimple.chartOptions.xaxis.categories = Object.keys(this.total_user_last_week[0].data);

            }
        },
        async mounted() {
            if (this.data.length > 0 && this.data) {
                this.today_approved = this.data[0].today_approved;
                this.today_rejected = this.data[0].today_rejected;
                this.today_pending = this.data[0].today_pending;
                this.total_call_count = this.data[0].total_call_count;
            }
            // await this.retrieveDashboardData();
        },
        methods: {
            ...mapActions({
                getAdminFilteredDashboardData: "appData/getAdminFilteredDashboardData",
                // getDashboardData: "appData/getDashboardData",
            }),
            async onModalClosed() {
            },
            async clickHandler(event, chartContext, config) {

                let selectedDonut = config.dataPointIndex;
                let selectedItem = config.w.config.labels[selectedDonut];
                try {
                    this.show = true;
                    this.searching_criteria.type = 'today_summary';
                    this.searching_criteria.status = selectedItem;
                    const res = await this.getAdminFilteredDashboardData(this.searching_criteria);
                    this.users = res.data.data.users;
                    this.show = false;
                    if (this.users.length > 0) {
                        this.viewModalCounting += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-today-filtered-user-modal");
                        });
                    }
                } catch (error) {
                    this.show = false;
                    this.displayError(error);
                }
            },
            // async retrieveDashboardData() {
            //     try {
            //         this.show = true;
            //         const _res = await this.getDashboardData();
            //         this.graphCount += 1;
            //         this.total_user = _res.data.total_user.toLocaleString();
            //         this.total_approved = _res.data.total_approved.toLocaleString();
            //         this.total_rejected = _res.data.total_rejected.toLocaleString();
            //         this.total_pending = _res.data.total_pending.toLocaleString();

            //         this.today_user = _res.data.today_user.toLocaleString();
            //         this.today_approved = _res.data.today_approved.toLocaleString();
            //         this.today_rejected = _res.data.today_rejected.toLocaleString();
            //         this.today_pending = _res.data.today_pending.toLocaleString();

            //         this.total_summary = _res.data.total_summary;
            //         this.today_summary = _res.data.today_summary;

            //         this.show = false;
            //     } catch (error) {
            //         this.show = false;
            //         this.displayError(error);
            //     }
            // },

        },
        ...mapGetters({
            hasPermission: "appData/hasPermission",
        }),
    };
</script>
<style scoped>
    .bg-image {
        background-image: url('~@/assets/images/newDashboard/Background-1.png');
        background-size: cover;
        background-position: center;
    }

    .shadow {
        box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
        -webkit-box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
        -moz-box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
    }

    .first::marker {
        color: rgb(14, 159, 217);
        font-size: 25px;
    }

    .second::marker {
        color: rgb(233, 116, 116);
        font-size: 25px;
    }

    .third::marker {
        color: rgb(85, 205, 162);
        font-size: 25px;
    }

    li {
        font-size: 18px;
    }

    label {
        font-size: 18px;
        font-weight: bolder;
        color: black;
    }
</style>