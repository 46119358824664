<template>
  <div style="height: 100%; background-color: #094120;">
      <div class="d-flex justify-content-center align-items-center h-100 ">
          <b-link class="text-light my-nav-item my-nav-link d-flex align-items-center"
                  :to="{ name: 'dashboard' }">
              <feather-icon icon="HomeIcon" size="16" class="mr-50 text-light" />
              <span>Home</span>
          </b-link>
          <b-link class="text-light my-nav-item my-nav-link d-flex align-items-center ml-2"
                  :to="{ name: 'Shuhada' }"
                  v-if="hasPermission('shuhada_show')">
              <feather-icon icon="UsersIcon" size="16" class="mr-50 text-light" />
              <span>Shuhada / ISD</span>
          </b-link>
          <b-link class="text-light my-nav-item my-nav-link d-flex align-items-center ml-2"
                  :to="{ name: 'WWP' }"
                  v-if="hasPermission('war_pers_show')">
              <feather-icon icon="UsersIcon" size="16" class="mr-50 text-light" />
              <span>WWPs</span>
          </b-link>
          <b-link class="text-light my-nav-item my-nav-link d-flex align-items-center ml-2"
                  :to="{ name: 'Family' }"
                  v-if="hasPermission('family_show')">
              <feather-icon icon="SlackIcon" size="16" class="mr-50 text-light" />
              <span>Family Details</span>
          </b-link>
          <b-link class="text-light my-nav-item my-nav-link d-flex align-items-center ml-2"
                  :to="{ name: 'Benefits' }"
                  v-if="hasPermission('detailbenefit_show')">
              <feather-icon icon="BriefcaseIcon" size="16" class="mr-50 text-light" />
              <span>Benefits Details</span>
          </b-link>
          <b-link class="text-light my-nav-item my-nav-link d-flex align-items-center ml-2"
                  :to="{ name: 'FinancialAssistant' }"
                  v-if="hasPermission('fin_asst_show')">
              <feather-icon icon="CpuIcon" size="16" class="mr-50 text-light" />
              <span>Fin Asst</span>
          </b-link>
          <b-dropdown text="Indiv Reports"
                      style="border: 2px solid #e1bf93 !important;"
                      class="m-0 p-0 ml-2"
                      variant="primary"
                      size="sm">
              <template #button-content>
                  <feather-icon icon="FileIcon" size="15" class="mr-50 text-light" />
                  Indl Reports
              </template>
              <b-dropdown-item v-if="hasPermission('certificate_show')"
                               :to="{ name: 'Certificate' }">Certificate</b-dropdown-item>
              <b-dropdown-item v-if="hasPermission('bio_show')"
                               :to="{ name: 'Biodata' }">Bio Data</b-dropdown-item>
              <b-dropdown-item v-if="hasPermission('bio_show')"
                               :to="{ name: 'NewBiodata' }">Event One Pager</b-dropdown-item>
              <b-dropdown-item v-if="hasPermission('bio_show')"
                               :to="{ name: 'PetitionReport' }">Petition Report</b-dropdown-item>
              <b-dropdown-item v-if="hasPermission('familyreport_show')"
                               :to="{ name: 'FamilyReport' }">Family Details</b-dropdown-item>
              <b-dropdown-item v-if="hasPermission('peroforma_show')"
                               :to="{ name: 'ProformaReport' }">Proforma</b-dropdown-item>
              <b-dropdown-item v-if="hasPermission('family_particulars_show')"
                               :to="{ name: 'FamilyParticularsReport' }">Family Particulars</b-dropdown-item>
              <b-dropdown-item v-if="hasPermission('particulars_wards_show')"
                               :to="{ name: 'ParticularsWardsReport' }">Particulars Wards</b-dropdown-item>
          </b-dropdown>
          <b-dropdown text="Indiv Reports"
                      style="border: 2px solid #e1bf93 !important;"
                      class="m-0 p-0 ml-2"
                      variant="primary"
                      size="sm">
              <template #button-content>
                  <feather-icon icon="FileIcon" size="15" class="mr-50 text-light" />
                  Summary Reports
              </template>
              <b-dropdown-item v-if="hasPermission('arm_summary_show')"
                               :to="{ name: 'ArmSummaryReport' }">Arm / Svc & Rank</b-dropdown-item>

              <b-dropdown-item v-if="hasPermission('cause_summary_show')"
                               :to="{ name: 'CauseSummaryReport' }">Cause / Death</b-dropdown-item>

              <b-dropdown-item v-if="hasPermission('center_summary_show')"
                               :to="{ name: 'CenterSummaryReport' }">Centre</b-dropdown-item>

              <b-dropdown-item v-if="hasPermission('district_summary_show')"
                               :to="{ name: 'DistrictSummaryReport' }">District / Province</b-dropdown-item>

              <b-dropdown-item v-if="hasPermission('invitation_summary_show')"
                               :to="{ name: 'InvitationSummary' }">Invitation</b-dropdown-item>

              <b-dropdown-item v-if="hasPermission('operation_summary_show')"
                               :to="{ name: 'OperationSummaryReport' }">Operation</b-dropdown-item>

              <b-dropdown-item v-if="hasPermission('province_summary_show')"
                               :to="{ name: 'ProvinceSummaryReport' }">Province</b-dropdown-item>

              <b-dropdown-item v-if="hasPermission('rank_cause_summary_show')"
                               :to="{ name: 'RankCauseSummaryReport' }">Rank / Cause</b-dropdown-item>

              <b-dropdown-item v-if="hasPermission('rank_corps_summary_show')"
                               :to="{ name: 'RankCorpsSummaryReport' }">Rank / Corps</b-dropdown-item>

              <b-dropdown-item v-if="hasPermission('rank_district_summary_show')"
                               :to="{ name: 'RankDistrictSummaryReport' }">Rank / District</b-dropdown-item>

              <b-dropdown-item v-if="hasPermission('rank_summary_show')"
                               :to="{ name: 'RankSummaryReport' }">Rank / Year</b-dropdown-item>

              <b-dropdown-item v-if="hasPermission('year_summary_show')"
                               :to="{ name: 'YearSummaryReport' }">Year Wise</b-dropdown-item>
          </b-dropdown>
          <b-dropdown text="Indiv Reports"
                      style="border: 2px solid #e1bf93 !important;"
                      class="m-0 p-0 ml-2"
                      variant="primary"
                      size="sm">
              <template #button-content>
                  <feather-icon icon="FileIcon" size="15" class="mr-50 text-light" />
                  WWP Summary Reports
              </template>
              <b-dropdown-item v-if="hasPermission('clwise_summary_show')"
                               :to="{ name: 'ClWiseSummaryReport' }">Cl Wise Summary</b-dropdown-item>
              <b-dropdown-item v-if="hasPermission('cl_year_wise_summary_show')"
                               :to="{ name: 'ClYearWiseSummaryReport' }">Cl Yr Wise Summary</b-dropdown-item>
              <b-dropdown-item v-if="hasPermission('indlcat_summary_show')"
                               :to="{ name: 'IndlCatSummaryReport' }">Indl Cat Summary</b-dropdown-item>

          </b-dropdown>
          <b-button type="submit"
                    variant="primary"
                    class="btn-loginn btn-border"
                    pill
                    @click="logoutButtonClick">
              Logout <feather-icon size="15" icon="LogOutIcon" class="mr-20" />
          </b-button>
      </div>
    <div></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getLoggedInUser",
    }),
  },
  methods: {
    ...mapActions({ logout: "appData/logout" }),
    async logoutButtonClick() {
      try {
        const res = await this.logout();
        if (res.status === 204) {
            this.$router.push({ name: "userLogin" });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
    .btn-loginn {
        margin-right: 0px;
        margin-left: 2%;
        float: right;
        border: 2px solid #e1bf93 !important;
    }
</style>
