<template>
    <b-modal :id="id"
             title="Rank Edit Modal"
             centered
             hide-footer
             size="xl"
             @hidden="reset"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true">
        <template #modal-title>
            <h2 class="m-0">{{heading}}</h2>
        </template>
        <b-table v-if="type_==='COMPLAINT'"
                 responsive="sm"
                 :fields="fields_complaint"
                 :items="users"
                 :paginated="true"
                 details-td-class="m-0 p-0"
                 small>
            <template #cell(index)="row">
                {{row.index + 1}}.
            </template>
        </b-table>
        <b-table v-if="type_==='PETITION'"
                 responsive="sm"
                 :fields="fields_petition"
                 :items="users"
                 :paginated="true"
                 details-td-class="m-0 p-0"
                 small>
            <template #cell(index)="row">
                {{row.index + 1}}.
            </template>
            <template #cell(location)="row">
                <span>
                    {{ getPetitionLocationValue(row.item.location) }}
                </span>
            </template>
            <template #cell(status)="row">
                <span>
                    {{ getPetitionStatusValue(row.item.status) == 'Closed' ? 'Processed' : getPetitionStatusValue(row.item.status) }}
                </span>
            </template>
            <template #cell(is_received)="row">
                <span>
                    {{ row.item.is_received ? "Yes" : "No" }}
                </span>
            </template>
        </b-table>

        <b-overlay :show="show"
                   spinner-variant="primary"
                   no-wrap />
    </b-modal>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from "vee-validate";
    import { required, email } from "@validations";
    import util from "../../../util";
    import { mapActions, mapGetters } from "vuex";
    import Ripple from 'vue-ripple-directive'
    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        props: {
            users: Array,
            id: String,
            heading: String,
            type_: String,
        },
        directives: {
            Ripple,
        },
        mixins: [util],
        data() {
            return {
                name: null,
                required,
                email,
                show: false,
                fields_petition: [
                    {
                        key: "index",
                        label: "Sr.",
                    },
                    { key: "name", label: "Name" },
                    { key: "cnic", label: "CNIC" },
                    //{ key: "title", label: "Title" },
                    //{ key: "subject", label: "Subject" },
                    //{ key: "location", label: "Location" },
                    { key: "is_received", label: "Received" },
                    { key: "status", label: "Status" },
                    //{ key: "final_comment", label: "Final Comment" },
                ],
                fields_complaint: [
                    {
                        key: "index",
                        label: "Sr.",
                    },
                    { key: "complainant.first_name", label: "Name" },
                    { key: "complainant.contact_no", label: "Mobile" },
                    { key: "complainant.marital_status_data.name", label: "Martial Status" },
                    { key: "complainant.serving_status_data.name", label: "Serving Status" },
                    { key: "relation_type_data.name", label: "Relation" },
                ],
                type: null,
                rejected: false,
            };
        },
        async mounted() {
            if (this.users && this.users.length>0) {
            }
        },
        methods: {
            ...mapActions("appData", ["updateRank"]),
            async validationForm() {
                const success = await this.$refs.rankEditValidation.validate();
                if (success) {
                    await this.submit();
                }
            },
            async submit() {
                try {
                    this.show = true;
                    const res = await this.updateRank({
                        name: this.name,
                        first_category: this.first_category.name,
                        pk: this.rank.id,
                    });
                    if (res.status === 200) {
                        this.$swal({
                            title: res.data.msg,
                            icon: "success",
                            timer: 1500
                        });
                        this.show = false;
                        await this.reset();
                        this.$nextTick(() => {
                            this.$bvModal.hide("view-filtered-user-modal");
                        });
                        this.$emit("modalClosed");
                    }
                } catch (error) {
                    this.show = false;
                    this.displayError(error);
                }
            },


            reset() {
                this.name = null;
                this.strength = null;
            },
        },
        computed: {
            ...mapGetters({
                hasPermission: "appData/hasPermission",
                getUser: "appData/getUser",
            }),
        },
    };
</script>

<style>
    .vSelectStyle .vs__deselect {
        fill: #fff !important;
    }
</style>
