<template>
    <div>
        <!-- card start class="bg-image shadow border-0" -->
        <!-- <vue-apex-charts type="bar"
                     height="400"
                     :options="lineChartSimple.chartOptions"
                     @dataPointSelection="clickHandler"
                     :series="lineChartSimple.series" /> -->
        <!-- <ul>
        <li class="first">Pending  <label >{{total_pending}}</label></li>

        <li class="second">Rejected  <label >{{total_rejected}}</label></li>

        <li class="third">Approved   <label >{{total_approved}}</label></li>

    </ul> -->
        <b-row > 
            <!-- <div class="d-flex "> -->
                <b-col class="col-md-7 col-12 ">
                    <!-- @click="getData('total_user')" cursor:pointer -->
                    <div style="" >
                        <b-img :src="require('@/assets/images/newDashboard/registeration.svg')"
                            alt="Medal Pic"
                            width="50px"
                            class="d-inline" />
                        <h3 class="d-inline" style="font-weight:bold">Registration</h3>
                    </div>
                    
                    <br>
                    <br>
                    <br>
                    <ul>
                        <!-- @click="getData('Serving', 'Serving List')" -->
                        <li class="first" >
                            Serving &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            <label style="color: #FF0000;">
                                {{total_serving_user}}
                            </label>
                        </li>
                        <!-- @click="getData('Retired', 'Retired List')" -->
                        <li class="second" >
                            Retired &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            <label style="color: #369000;">
                                {{total_retired_user}}
                            </label>
                        </li>
                        <!-- @click="getData('Civil', 'Family of Shaheed List')" -->
                        <li class="third" >
                            Shuhada Family&emsp;&emsp;
                            <label style="color: #00A9DF;">
                                {{total_civil_user}}
                            </label>
                        </li>

                    </ul>
                </b-col>

                <b-col class="col-md-5 col-12 ">
                    <vue-apex-charts type="donut"
                                        height="380"
                                        :options="donutChart.chartOptions"
                                        :series="donutChart.series" />
                </b-col>
            <!-- </div> -->
            <!-- @dataPointSelection="clickHandler" -->
        </b-row>
        <!-- <hr> -->
        <!-- <b-row>
            <b-col>
                <div style="display: flex;">
                    <div>
                        <h3 class="font-weight-bolder">
                            <b-img :src="require('@/assets/images/newDashboard/call.svg')"
                                   alt="Medal Pic"
                                   width="30px" /> &nbsp;&nbsp;
                            Number of Calls
                        </h3>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div>
                        <p style="color:rgb(14, 159, 217); font-weight:bolder; font-size: 30px;">
                            {{total_call_count}}
                        </p>
                    </div>
                </div>
            </b-col>
        </b-row> -->

        <!-- card end -->
        <b-overlay :show="show"
                   spinner-variant="primary"
                   no-wrap />
        <view-filtered-user-modal :id="`view-total-filtered-user-modal`"
                                  :key="`view_filtered-${viewModalCounted}`"
                                  :users="users"
                                  :heading="heading"
                                  :obj_for_filtering="obj_for_filtering"
                                   />
    </div>

</template>

<script>
    import {
        BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge,
    } from 'bootstrap-vue'
    import VueApexCharts from 'vue-apexcharts'
    import { $themeColors } from '@themeConfig'
    import { mapActions, mapGetters } from "vuex";
    import util from "../../../util";
    import ViewFilteredUserModal from "./ViewFilteredUserModal.vue";

    export default {
        components: {
            VueApexCharts,
            BCardHeader,
            BCard,
            BBadge,
            BCardBody,
            BCardTitle,
            BCardSubTitle,
            ViewFilteredUserModal,
        },
        props: {
            details: Array,
            data: Array,
        },
        mixins: [util],
        data() {
            return {
                donutChart: {
                    series: [85, 16, 50],
                    chartOptions: {
                        legend: {
                            show: true,
                            position: 'bottom',
                            fontSize: '14px',
                            fontFamily: 'Montserrat',

                        },
                        colors: [
                            '#00d4bd',
                            '#826bf8',
                            '#AA4A44',
                        ],
                        dataLabels: {
                            enabled: false,
                            formatter(val) {
                                // eslint-disable-next-line radix
                                return `${parseInt(val)}`;
                            },
                        },
                        plotOptions: {
                            pie: {
                                donut: {
                                    labels: {
                                        show: true,
                                        name: {
                                            fontSize: '2rem',
                                            fontFamily: 'Montserrat',
                                        },
                                        value: {
                                            fontSize: '2rem',
                                            fontWeight: 'bolder',
                                            fontFamily: 'Montserrat',
                                            formatter(val) {
                                                // eslint-disable-next-line radix
                                                return `${parseInt(val)}`
                                            },
                                        },
                                        total: {
                                            show: true,
                                            fontSize: '1.5rem',
                                            label: 'Total',
                                            formatter(series) {
                                                const total = series.config.series.reduce((acc, val) => acc + val, 0);
                                                return total.toString();
                                            },
                                        },
                                    },
                                },
                            },

                        },
                        // title: {
                        //    text: 'Total Summary',

                        //    margin: 10,
                        //    offsetX: 0,
                        //    offsetY: 0,
                        //    floating: false,
                        //    style: {
                        //        fontSize: '25px',
                        //        fontWeight: 'bolder',
                        //        color: '#535858'
                        //    },
                        // },
                        labels: ['Approved', 'Rejected', 'Pending'],
                        // responsive: [
                        //     {
                        //         breakpoint: 1115,
                        //         options: {
                        //             chart: {
                        //                 height: 380,
                        //             },
                        //             legend: {
                        //                 position: 'bottom',
                        //             },
                        //         },
                        //     },
                        //     {
                        //         breakpoint: 576,
                        //         options: {
                        //             chart: {
                        //                 height: 320,
                        //             },
                        //             plotOptions: {
                        //                 pie: {
                        //                     donut: {
                        //                         labels: {
                        //                             show: true,
                        //                             name: {
                        //                                 fontSize: '1.5rem',
                        //                             },
                        //                             value: {
                        //                                 fontSize: '1rem',
                        //                             },
                        //                             total: {
                        //                                 fontSize: '1.5rem',
                        //                             },
                        //                         },
                        //                     },
                        //                 },
                        //             },
                        //             legend: {
                        //                 show: true,
                        //             },
                        //         },
                        //     },
                        // ],
                    },
                },
                lineChartSimple: {
                    series: [
                        {
                            data: [280, 200, 220, 180, 512],
                        },
                    ],
                    chartOptions: {
                        title: {
                            text: 'Total Summary',
                            align: 'center',
                            margin: 10,
                            offsetX: 0,
                            offsetY: 0,
                            floating: false,
                            style: {
                                fontSize: '25px',
                                fontWeight: 'bolder',
                                color: '#3f622d'
                            },
                        },
                        chart: {
                            zoom: {
                                enabled: false,
                            },
                            toolbar: {
                                show: true,
                            },
                        },
                        markers: {
                            strokeWidth: 7,
                            strokeOpacity: 1,
                            strokeColors: [$themeColors.light],
                            colors: [$themeColors.warning],
                        },
                        colors: ['#9e2a2b'],
                        dataLabels: {
                            enabled: true,
                        },
                        stroke: {
                            curve: 'straight',
                        },
                        grid: {
                            xaxis: {
                                lines: {
                                    show: true,
                                },
                            },
                        },
                        tooltip: {
                            custom(data) {
                                return `${'<div class="px-1 py-50"><span>'}${data.series[data.seriesIndex][data.dataPointIndex]
                                    } Users</span></div>`
                            },
                        },
                        xaxis: {
                            categories: ['Approved',
                                'Rejected',
                                'Pending',],
                        },
                        yaxis: {
                            // opposite: isRtl,
                        },
                    },
                },
                data_array: [],
                searching_criteria: {},
                users: null,
                show: false,
                viewModalCounted: 0,
                total_pending: 0,
                total_approved: 0,
                total_rejected: 0,
                total_serving_user: 0,
                total_retired_user: 0,
                total_civil_user: 0,
                total_call_count: 0,
                heading: "",
                obj_for_filtering:null,
            }
        },
        async created() {
            if (this.details) {
                // console.log("details", this.details);
                this.data_array = this.details;
                this.lineChartSimple.series[0].data = this.data_array;
                this.donutChart.series = this.data_array;
            }

        },
        async mounted() {
            // console.log("hdkjdhalkhsdkljh",this.data[0])
            if (this.data.length > 0 && this.data) {
                // console.log("hdkjdhalkhsdkljh",this.data[0])

                this.total_serving_user = this.data[0].total_serving_user.toLocaleString();
                this.total_retired_user = this.data[0].total_retired_user.toLocaleString();
                this.total_civil_user = this.data[0].total_civil_user.toLocaleString();
                this.total_call_count = this.data[0].total_call_count.toLocaleString();
            }

            // await this.retrieveDashboardData();
        },
        methods: {
            ...mapActions({
                getAdminFilteredDashboardData: "appData/getAdminFilteredDashboardData",
                // getDashboardData: "appData/getDashboardData",
            }),
            async onModalClosed() {
            },
            async clickHandler(event, chartContext, config) {
                //let selectedDonut = config.dataPointIndex;
                //let selectedItem = config.w.config.xaxis.categories[selectedDonut];
                let selectedDonut = config.dataPointIndex;
                let selectedItem = config.w.config.labels[selectedDonut];
                try {

                    this.show = true;
                    this.searching_criteria.type = 'total_summary';
                    this.searching_criteria.status = selectedItem;
                    const res = await this.getAdminFilteredDashboardData(this.searching_criteria);
                    this.users = res.data.data.users;
                    this.show = false;
                    if (this.users.length > 0) {
                        this.obj_for_filtering = this.searching_criteria;
                        this.viewModalCounted += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-total-filtered-user-modal");
                        });
                    }

                }
                catch (error) {
                    this.displayError(error);
                    this.show = false;
                }

            },
            async getData(data, heading) {
                var obj = null;
                this.heading = heading;
                if (data === 'total_user') {
                    this.show = true;
                    obj = {
                        type: 'total_user',
                        status:'',
                    }
                    const _res = await this.getAdminFilteredDashboardData(obj);
                    this.users = _res.data.data.users;
                    this.obj_for_filtering = obj;
                    this.viewModalCounted += 1;
                    this.$nextTick(() => {
                        this.$bvModal.show("view-total-filtered-user-modal")
                    })
                    this.show = false;
                }
               else if (data === 'Serving') {
                    this.show = true;
                    obj = {
                        type: 'Serving',
                        status: ""
                    };
                    const _res = await this.getAdminFilteredDashboardData(obj);
                    this.users = _res.data.data.users;
                    this.obj_for_filtering = obj;
                    this.viewModalCounted += 1;
                    this.$nextTick(() => {
                        this.$bvModal.show("view-total-filtered-user-modal")
                    })
                    this.show = false;
                }
                else if (data === 'Retired') {
                    this.show = true;
                    obj = {
                        type: 'Retired',
                        status: ""
                    };
                    const _res = await this.getAdminFilteredDashboardData(obj);
                    this.users = _res.data.data.users;
                    this.obj_for_filtering = obj;
                    this.viewModalCounted += 1;
                    this.$nextTick(() => {
                        this.$bvModal.show("view-total-filtered-user-modal")
                    })
                    this.show = false;
                }
                else if (data === 'Civil') {
                    this.show = true;
                    obj = {
                        type: 'Civil',
                        status: ""
                    };
                    const _res = await this.getAdminFilteredDashboardData(obj);
                    this.users = _res.data.data.users;
                    this.obj_for_filtering = obj;
                    this.viewModalCounted += 1;
                    this.$nextTick(() => {
                        this.$bvModal.show("view-total-filtered-user-modal")
                    })
                    this.show = false;
                }
            }
            // async retrieveDashboardData() {
            //     try {
            //         this.show = true;
            //         const _res = await this.getDashboardData();
            //         this.graphCount += 1;
            //         this.total_user = _res.data.total_user.toLocaleString();
            //         this.total_approved = _res.data.total_approved.toLocaleString();
            //         this.total_rejected = _res.data.total_rejected.toLocaleString();
            //         this.total_pending = _res.data.total_pending.toLocaleString();

            //         this.today_user = _res.data.today_user.toLocaleString();
            //         this.today_approved = _res.data.today_approved.toLocaleString();
            //         this.today_rejected = _res.data.today_rejected.toLocaleString();
            //         this.today_pending = _res.data.today_pending.toLocaleString();

            //         this.total_summary = _res.data.total_summary;
            //         this.today_summary = _res.data.today_summary;

            //         this.show = false;
            //     } catch (error) {
            //         this.show = false;
            //         this.displayError(error);
            //     }
            // },
        },
        ...mapGetters({
            hasPermission: "appData/hasPermission",
        }),
    };
</script>
<style scoped>
    .bg-image {
        background-image: url('~@/assets/images/newDashboard/Background-1.png');
        background-size: cover;
        background-position: center;
    }

    .shadow {
        box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
        -webkit-box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
        -moz-box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
    }

    .first::marker {
        color: #FF0000;
        font-size: 25px;
    }

    .second::marker {
        color: #369000;
        font-size: 25px;
    }

    .third::marker {
        color: #00A9DF;
        font-size: 25px;
    }

    li {
        font-size: 18px;
        
    }
    /* cursor: pointer; */
    label {
        font-size: 18px;
        font-weight: bolder;
    }
     hr {
        border: 0;
        clear: both;
        display: block;
        width: 96%;
        background-color: #413f3fa2;
        height: 1px;
    }
</style>