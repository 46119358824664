<template>
    <div>
        <div id="dashboard">
            <div>
                <b-card class="shadow ">
                    <b-row v-if="show_data == false" class="mb-0">
                        
                        <b-col class="text-right" cols="5">
                            <img src="@/assets/images/logo/afp-logo.png" alt="logo" width="90px" height="90px">
                        </b-col>
                        <b-col class="text-start mt-2 pl-0">
                            <h2 class="text-primary"
                                style="font-size: 4rem;font-weight: bolder;font-family: 'Citadel Script W01';">
                                <!--FACILITATION PORTAL-->
                                Facilitation Portal
                            </h2>
                        </b-col>
                        <div class="d-flex justify-content-end align-items-center flex-nowrap mr-2 mb-0">
                                <b-button variant="primary" pill :to="{ name: 'LandingPage' }">
                                    <span class="align-middle">Home</span>
                                </b-button>
                        </div>
                    </b-row>


                    <b-row>
                        <b-col v-if="show_data==true">
                            <div class="d-flex justify-content-end align-items-center flex-nowrap mr-2 mb-0">
                                <b-button variant="primary" pill @click="generateReportModal()">
                                    <feather-icon icon="DownloadIcon" class="mr-50" />
                                    <span class="align-middle">Generate Report</span>
                                </b-button>
                            </div>
                        </b-col>
                        <b-col class="col-md-12" v-if="show_data==true">
                            <div class="text-center">
                                <h2 style="font-weight: 600; font-size: x-large;" class="text-dark text-uppercase">Summary Details</h2>
                            </div>
                        </b-col>
                        <!-- <b-col v-if="show_data==true">
                            <b-button

                                @click="showToast('info')"
                            >
                                Info
                            </b-button>
                        </b-col> -->

                    </b-row>

                    <b-card class="bg-image-1 shadow ">
                        <b-row class="">
                            <b-col class="col-md-5 col-12 total_summary">
                                <total-summary-line-chart :details="total_summary"
                                                          :data="user_data"
                                                          :key="`yearwise-${graphCount}`" />
                                <hr class="break-hr">
                                <b-row>
                                    <b-col>
                                        <!-- card start class="bg-image shadow border-0 mb-1"-->
                                        <div style="display: flex;">
                                            <div>
                                                <h3 class="font-weight-bolder ">
                                                    <b-img :src="require('@/assets/images/newDashboard/call.svg')"
                                                           alt="Medal Pic"
                                                           width="30px" /> &nbsp;&nbsp;
                                                    Number of Calls
                                                </h3>
                                            </div>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <div>
                                                <p style="color:rgb(14, 159, 217); font-weight:bolder; font-size: 30px; ">
                                                    {{total_call_count}}
                                                </p>
                                            </div>
                                        </div>
                                        <!-- card end -->
                                    </b-col>
                                </b-row>
                                <div class="hre"></div>
                            </b-col>
                            <b-col class="col-md-2 col-12 seperator">
                                <!-- card start class="bg-image shadow " @click="getData('total_approved', 'Approved List')"-->
                                <div class="px-2 ">
                                    <div style="display: flex; align-items: center;">
                                        <div style="flex: 1;">
                                            <h3 style="font-weight: bolder; margin-top: 7px;">
                                                <strong>Approved</strong>
                                            </h3>
                                            <h3 class="mb-75">
                                                <div style="color: rgb(85, 205, 162);  font-weight: bolder; font-size: 30px;">{{total_approved}}</div>
                                            </h3>
                                        </div>
                                        <div>
                                            <b-img :src="require('@/assets/images/newDashboard/approved 1.svg')"
                                                   class="cong-medal"
                                                   style="width: max(2.976vw, 40px)"
                                                   alt="Medal Pic" />
                                        </div>
                                    </div>
                                    <!-- <br>  @click="getData('total_rejected', 'Total Rejected List')"-->
                                    <hr>
                                    <div style="display: flex; align-items: center;">
                                        <div style="flex: 1;">
                                            <h3 style="font-weight: bolder; margin-top: 7px;">
                                                <strong>Rejected</strong>
                                            </h3>
                                            <h3 class="mb-75">
                                                <div style="color:#ff9f43; font-weight: bolder; font-size: 30px;">{{total_rejected}}</div>
                                            </h3>
                                        </div>
                                        <div>
                                            <b-img :src="require('@/assets/images/newDashboard/reject-n 1.svg')"
                                                   class="cong-medal"
                                                   style="width: max(2.976vw, 40px)"
                                                   alt="Medal Pic" />
                                        </div>
                                    </div>
                                    <!-- <br> @click="getData('total_pending', 'Total Pending List')"-->
                                    <hr>

                                    <div style="display: flex; align-items: center;">
                                        <div style="flex: 1;">
                                            <h3 style="font-weight: bolder; margin-top: 7px;">
                                                <strong>Pending</strong>
                                            </h3>
                                            <h3 class="mb-75">
                                                <div style="color:rgb(14, 159, 217); font-weight: bolder; font-size: 30px;">{{total_pending}}</div>
                                            </h3>
                                        </div>
                                        <div>
                                            <b-img :src="require('@/assets/images/newDashboard/pending.svg')"
                                                   class="cong-medal"
                                                   style="width: max(2.976vw, 40px)"
                                                   alt="Medal Pic" />
                                        </div>
                                    </div>
                                    <br>
                                </div>
                                <!-- <div id="pageBreakers" >

                                </div> -->
                                <!-- card end -->
                            </b-col>
                            <b-col class="col-md-5 col-12 today_summary">

                                <today-summary-line-chart :details="today_summary"
                                                          :data="user_data"
                                                          :total_user_last_week="total_summary_line_chart_data"
                                                          :key="`isd-${graphCount}`" />

                            </b-col>
                        </b-row>
                        <hr>
                        <b-row style="margin-top:-11px">
                            <b-col class="col-md-4 summary">
                                <div class="bg-image ">
                                    <b-col>
                                        <h4 style="font-weight: bolder; font-size: 20px; margin-top: 5px; ">
                                            <strong>Bookings</strong>
                                        </h4>
                                        <ul>
                                            <li style="margin-top:-5px"
                                                class="first" @click="getData('total_pending_booking', 'Pending List')">
                                                Pending &emsp;&emsp;
                                                <label class="summary_value" style="font-weight: bolder; color:rgb(14, 159, 217);">
                                                    {{total_pending_booking}}
                                                </label>
                                            </li>

                                            <li style="margin-top:-5px"
                                                class="second" @click="getData('total_confirmed_booking', 'Confirmed List')">
                                                Confirmed &emsp;
                                                <label class="summary_value" style="font-weight: bolder; color:#ff9f43;">
                                                    {{total_confirmed_booking}}
                                                </label>
                                            </li>

                                            <li style="margin-top:-5px"
                                                class="third" @click="getData('total_booking','All Bookings List')">
                                                Total &emsp;&emsp;&emsp;&emsp;
                                                <label class="summary_value" style="font-weight: bolder; color:rgb(85, 205, 162);">
                                                    {{total_booking}}
                                                </label>
                                            </li>

                                        </ul>
                                        <!-- <h3 class="mb-75 mt-2">
                                            <b-link style="color:rgb(14, 159, 217); font-weight:bolder; font-size: 30px;">{{total_booking}}</b-link>
                                        </h3> -->

                                        <b-img :src="require('@/assets/images/newDashboard/booking.svg')"
                                               class="congratulation-medal"
                                               width="50px"
                                               alt="Medal Pic" />
                                    </b-col>
                                </div>
                            </b-col>

                            <!--<b-col>
                                <b-card class="bg-image shadow">
                                    <b-col style="width: 18rem;" @click="getData('total_complaint', 'All Complaints List')">

                                        <h4 style="font-weight: bolder; font-size: 20px; margin-top: 13px; ">
                                            <strong>Complaints</strong>
                                        </h4>
                                        <h3 class="mb-75 mt-2">
                                            <b-link style="color:rgb(233 116 116); font-weight:bolder; font-size: 30px;">{{total_complaint}}</b-link>
                                        </h3>

                                        <b-img :src="require('@/assets/images/newDashboard/complaint.svg')"
                                               class="congratulation-medal"
                                               alt="Medal Pic"
                                               width="50px" />
                                    </b-col>
                                </b-card>
                            </b-col>-->
                                                        <b-col class="col-md-4 summary seperator">
                                <div class="bg-image ">
                                    <div id="pageBreakers"></div>
                                    <b-col>
                                        <h4 style="font-weight: bolder; font-size: 20px; margin-top: 5px;">
                                            <strong>Petitions</strong>
                                        </h4>
                                        <ul>
                                            <li style="margin-top:-5px"
                                                class="first" @click="getData('total_pending_petition', 'Pending List')">
                                                Pending &emsp;&emsp;
                                                <label class="summary_value" style="font-weight: bolder; color:rgb(14, 159, 217);">
                                                    {{total_pending_petition}}
                                                </label>
                                            </li>

                                            <li style="margin-top:-5px"
                                                class="second" @click="getData('total_processed_petition', 'Processed List')">
                                                Processed&emsp;
                                                <label class="summary_value" style="font-weight: bolder; color:#ff9f43;">
                                                    {{total_processed_petition}}
                                                </label>
                                            </li>

                                            <li style="margin-top:-5px"
                                                class="third" @click="getData('total_petition', 'All Petitions List')">
                                                Total&emsp;&emsp;&emsp;&emsp;
                                                <label class="summary_value" style="font-weight: bolder; color:rgb(85, 205, 162);">
                                                    {{total_petition}}
                                                </label>
                                            </li>

                                        </ul>
                                        <!-- <h3 class="mb-75 mt-2">
                                            <b-link style="color:#e18e3d; font-weight:bolder; font-size: 30px;">{{total_petition}}</b-link>
                                        </h3> -->
                                        <b-img :src="require('@/assets/images/newDashboard/petition.svg')"
                                               class="congratulation-medal"
                                               alt="Medal Pic"
                                               width="50px" />
                                    </b-col>
                                </div>
                            </b-col>
                            <!-- <div class="breaker"></div> -->
                            <b-col class="col-md-4 summary ">
                                <div class="bg-image ">
                                    <b-col>
                                        <h4 style="font-weight: bolder; font-size: 20px; margin-top: 5px;">
                                            <strong>NOCs</strong>
                                        </h4>
                                        <ul>
                                            <li style="margin-top:-5px"
                                                class="first" @click="getData('total_pending_noc', 'Pending List')">
                                                Pending &emsp;&emsp;
                                                <label class="summary_value" style="font-weight: bolder; color:rgb(14, 159, 217);">
                                                    {{total_pending_noc}}
                                                </label>
                                            </li>

                                            <li style="margin-top:-5px"
                                                class="second" @click="getData('total_processed_noc', 'Processed List')">
                                                Processed&emsp;
                                                <label class="summary_value" style="font-weight: bolder; color:#ff9f43;">
                                                    {{total_processed_noc}}
                                                </label>
                                            </li>

                                            <li style="margin-top:-5px"
                                                class="third" @click="getData('total_noc', 'All NOCs List')">
                                                Total&emsp;&emsp;&emsp;&emsp;
                                                <label class="summary_value" style="font-weight: bolder; color:rgb(85, 205, 162);">
                                                    {{total_noc}}
                                                </label>
                                            </li>

                                        </ul>
                                        <!-- <h3 class="mb-75 mt-2">
                                            <b-link style="color:rgb(85, 205, 162); font-weight:bolder; font-size: 30px;">{{total_noc}}</b-link>
                                        </h3> -->
                                        <b-img :src="require('@/assets/images/newDashboard/NOC.svg')"
                                               class="congratulation-medal"
                                               alt="Medal Pic"
                                               width="50px" />
                                    </b-col>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                    <!-- </div> -->
                    <div id="pageBreakers"></div>  
                    <h2 v-if="show_data == false"
                        style="font-size: 2.7rem; font-weight: bolder; margin-bottom: 1%; font-family: 'Citadel Script W01'; "
                        class="text-primary text-center ">
                        Today's Statistics
                    </h2>
                    <h2 v-else
                        style="font-weight: 600; font-size: x-large; margin-bottom:1%"
                        class="text-dark text-center text-uppercase">
                        Today's Statistics
                    </h2>
                    <b-card class="bg-image shadow ">
                        <b-row class="">
                            <b-col class="col-md-5 col-12 today_registration_pie">
                                <today-registration-pie-chart :details="today_summary"
                                                              :data="user_data"
                                                              :key="`today-${graphCount}`" />
                                <hr class="break-hr">
                                <b-row>
                                    <b-col>
                                        <div style="display: flex;">
                                            <div>
                                                <h3 class="font-weight-bolder">
                                                    <b-img :src="require('@/assets/images/newDashboard/call.svg')"
                                                           alt="Medal Pic"
                                                           width="30px" /> &nbsp;&nbsp;
                                                    Number of Calls
                                                </h3>
                                            </div>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <div>
                                                <p style="color:rgb(14, 159, 217); font-weight:bolder; font-size: 30px;">
                                                    {{today_call_count}}
                                                </p>
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>
                                                                <div class="hre"></div>
                            </b-col>
                            <b-col class="col-md-2 col-12 seperator today_approved ">
                                <!-- card start class="bg-image shadow " seperator_today-->
                                <div class="px-2 ">
                                    <div style="display: flex; align-items: center;" @click="getData('today_approved', 'Today Approved List')">
                                        <div style="flex: 1;">
                                            <h3 style="font-weight: bolder; margin-top: 7px;">
                                                <strong>Approved</strong>
                                            </h3>
                                            <h3 class="mb-75">
                                                <b-link style="color: rgb(85, 205, 162); font-weight: bolder; font-size: 30px;">{{today_approved}}</b-link>
                                            </h3>
                                        </div>
                                        <div>
                                            <b-img :src="require('@/assets/images/newDashboard/approved 1.svg')"
                                                   class="cong-medal"
                                                   style="width: max(2.976vw, 40px)"
                                                   alt="Medal Pic" />
                                        </div>
                                    </div>
                                    <!-- <br> -->
                                    <hr>
                                    <div style="display: flex; align-items: center;" @click="getData('today_rejected', 'Today Rejected List')">
                                        <div style="flex: 1;">
                                            <h3 style="font-weight: bolder; margin-top: 7px;">
                                                <strong>Rejected</strong>
                                            </h3>
                                            <h3 class="mb-75">
                                                <b-link style="color:#ff9f43; font-weight: bolder; font-size: 30px;">{{today_rejected}}</b-link>
                                            </h3>
                                        </div>
                                        <div>
                                            <b-img :src="require('@/assets/images/newDashboard/reject-n 1.svg')"
                                                   class="cong-medal"
                                                   style="width: max(2.976vw, 40px)"
                                                   alt="Medal Pic" />
                                        </div>
                                    </div>
                                    <!-- <br> -->
                                    <hr>

                                    <div style="display: flex; align-items: center;" @click="getData('today_pending', 'Today Pending List')">
                                        <div style="flex: 1;">
                                            <h3 style="font-weight: bolder; margin-top: 7px;">
                                                <strong>Pending</strong>
                                            </h3>
                                            <h3 class="mb-75">
                                                <b-link style="color:rgb(14, 159, 217); font-weight: bolder; font-size: 30px;">{{today_pending}}</b-link>
                                            </h3>
                                        </div>
                                        <div>
                                            <b-img :src="require('@/assets/images/newDashboard/pending.svg')"
                                                   class="cong-medal"
                                                   style="width: max(2.976vw, 40px)"
                                                   alt="Medal Pic" />
                                        </div>
                                    </div>
                                </div>
                                <!-- card end -->
                            </b-col>
                            <!-- Added new -->

                            <b-col class="col-md-5 col-12">
                                <div class="px-2 ">
                                    <div style="display: flex; align-items: center;" @click="getData('today_booking', 'Today Booking List')">
                                        <div style="flex: 1;">
                                            <h3 style="font-weight: bolder; margin-top: 7px;">
                                                <strong>Bookings</strong>
                                            </h3>
                                            <h3 class="mb-75">
                                                <b-link style="color: rgb(85, 205, 162);  font-weight: bolder; font-size: 30px;">{{today_booking}}</b-link>
                                            </h3>
                                        </div>
                                        <div>
                                            <b-img :src="require('@/assets/images/newDashboard/booking.svg')"
                                                   class="cong-medal"
                                                   style="width: max(2.976vw, 40px)"
                                                   alt="Medal Pic" />
                                        </div>
                                    </div>
                                    <!-- <br>  @click="getData('total_rejected', 'Total Rejected List')"-->
                                    <hr>
                                    <div style="display: flex; align-items: center; " @click="getData('today_petition', 'Today Petition List')">
                                        <div style="flex: 1;">
                                            <h3 style="font-weight: bolder; margin-top: 7px;">
                                                <strong>Petitions</strong>
                                            </h3>
                                            <h3 class="mb-75">
                                                <b-link style="color:#ff9f43; font-weight: bolder; font-size: 30px;">{{today_petition}}</b-link>
                                            </h3>
                                        </div>
                                        <div>
                                            <b-img :src="require('@/assets/images/newDashboard/petition.svg')"
                                                   class="cong-medal"
                                                   style="width: max(2.976vw, 40px)"
                                                   alt="Medal Pic" />
                                        </div>
                                    </div>
                                    <!-- <br> @click="getData('total_pending', 'Total Pending List')"-->
                                    <hr>

                                    <div style="display: flex; align-items: center;" @click="getData('today_noc', 'Today NOC List')">
                                        <div style="flex: 1;">
                                            <h3 style="font-weight: bolder; margin-top: 7px;">
                                                <strong>NOCs</strong>
                                            </h3>
                                            <h3 class="mb-75">
                                                <b-link style="color:rgb(14, 159, 217); font-weight: bolder; font-size: 30px;">{{today_noc}}</b-link>
                                            </h3>
                                        </div>
                                        <div>
                                            <b-img :src="require('@/assets/images/newDashboard/NOC.svg')"
                                                   class="cong-medal"
                                                   style="width: max(2.976vw, 40px)"
                                                   alt="Medal Pic" />
                                        </div>
                                    </div>
                                    <br>
                                </div>
                            </b-col>
                        </b-row>
                        <!-- <b-row>
                            <hr>
                            <b-col md="4">
                                <div class="bg-image ">
                                    <b-col @click="getData('today_booking', 'Today Booking List')">

                                        <h4 style="font-weight: bolder; font-size: 20px; margin-top: 13px; ">
                                            <strong>Bookings</strong>
                                        </h4>
                                        <h3 class="mb-75 mt-2">
                                            <b-link style="color:rgb(14, 159, 217); font-weight:bolder; font-size: 30px;">{{today_booking}}</b-link>
                                        </h3>

                                        <b-img :src="require('@/assets/images/newDashboard/booking.svg')"
                                               class="congratulation-medal"
                                               width="50px"
                                               alt="Medal Pic" />
                                    </b-col>
                                </div>
                            </b-col>

                            <b-col md="4">
                                <div class="bg-image seperator">
                                    <b-col @click="getData('today_petition', 'Today Petition List')">
                                        <h4 style="font-weight: bolder; font-size: 20px; margin-top: 13px;">
                                            <strong>Petitions</strong>
                                        </h4>
                                        <h3 class="mb-75 mt-2">
                                            <b-link style="color:#e18e3d; font-weight:bolder; font-size: 30px;">{{today_petition}}</b-link>
                                        </h3>
                                        <b-img :src="require('@/assets/images/newDashboard/petition.svg')"
                                               class="congratulation-medal"
                                               alt="Medal Pic"
                                               width="50px" />
                                    </b-col>
                                </div>
                            </b-col>

                            <b-col md="4">
                                <div class="bg-image ">
                                    <b-col @click="getData('today_noc', 'Today NOC List')">
                                        <h4 style="font-weight: bolder; font-size: 20px; margin-top: 13px;">
                                            <strong>NOCs</strong>
                                        </h4>
                                        <h3 class="mb-75 mt-2">
                                            <b-link style="color:rgb(85, 205, 162); font-weight:bolder; font-size: 30px;">{{today_noc}}</b-link>
                                        </h3>
                                        <b-img :src="require('@/assets/images/newDashboard/NOC.svg')"
                                               class="congratulation-medal"
                                               alt="Medal Pic"
                                               width="50px" />
                                    </b-col>
                                </div>
                            </b-col>
                        </b-row> -->

                    </b-card>
                <div>
                    <b-card class="bg-image-1 shadow ">
                        <b-row style="margin-top:-11px">
                            <b-col class="col-md-4 summary">
                                <div class="bg-image ">
                                    <b-col>
                                        <h4 style="font-weight: bolder; font-size: 20px; margin-top: 5px; ">
                                            <b-img :src="require('@/assets/images/newDashboard/message_sms_icon.svg')"
                                                           alt="Medal Pic"
                                                           width="50px" class="mr-1"/>
                                            <strong>TOTAL SMS STATISTICS</strong>
                                        </h4>
                                        <ul>
                                            <li style="margin-top:-5px"
                                                class="first">
                                                Guest Room Application &emsp;&emsp;
                                                <label class="summary_value" style="font-weight: bolder; color:#ff9f43;">
                                                    {{total_booking_sms}}
                                                </label>
                                            </li>

                                            <li style="margin-top:-5px"
                                                class="second">
                                                Total &emsp;&emsp;&emsp;&emsp;
                                                <label class="summary_value" style="font-weight: bolder; color:rgb(85, 205, 162);">
                                                    {{total_sms}}
                                                </label>
                                            </li>

                                        </ul>
                                        
                                        
                                    </b-col>
                                </div>
                            </b-col>
                            <hr>
                                <b-col class="col-md-4 summary">
                                    
                                        <div class="bg-image ">
                                            <b-col>
                                                <h4 style="font-weight: bolder; font-size: 20px; margin-top: 5px; ">
                                                    <b-img :src="require('@/assets/images/newDashboard/message_sms_icon.svg')"
                                                            alt="Medal Pic"
                                                            width="50px" class="mr-1"/>
                                                    <strong>TODAY'S SMS STATISTICS</strong>
                                                </h4>
                                                <ul>
                                                    <li style="margin-top:-5px"
                                                        class="first">
                                                        Guest Room Application &emsp;&emsp;
                                                        <label class="summary_value" style="font-weight: bolder; color:#ff9f43;">
                                                            {{today_booking_sms}}
                                                        </label>
                                                    </li>

                                                    <li style="margin-top:-5px"
                                                        class="second">
                                                        Total &emsp;&emsp;&emsp;&emsp;
                                                        <label class="summary_value" style="font-weight: bolder; color:rgb(85, 205, 162);">
                                                            {{today_sms}}
                                                        </label>
                                                    </li>

                                                </ul>
                                            
                                            
                                            </b-col>
                                        </div>
                                </b-col>
                        </b-row>
                    </b-card>
            
                </div>
                

                    <!--<b-row class="match-height">
                        <b-col md="6">
                            <b-card class="registrationImg shadow">
                                <div style="">
                                    <div style="cursor:pointer; width:60%;" @click="getData('today_user', 'Today Registeration List')">
                                        <h4 style="font-weight:bolder; font-size: 20px; margin-top:13px; ">
                                            <strong>Registration</strong> <label class="ml-1" for=""><b-link style="color:rgb(233, 116, 116); font-weight:bolder; font-size: 30px;">{{today_user}}</b-link></label>
                                        </h4>
                                    </div>
                                    <ul>
                                        <li style="cursor:pointer; " class="first" @click="getData('today_serving', 'Serving List')">
                                            Serving
                                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                            <label style="color:rgb(14, 159, 217);">{{today_serving}}</label>
                                        </li>

                                        <li style="cursor:pointer; " class="second" @click="getData('today_retired', 'Retired List')">
                                            Retired
                                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                            <label style="color:#ff9f43;">{{today_retired}}</label>
                                        </li>

                                        <li style="cursor:pointer; " class="third" @click="getData('today_civil', 'Family of Shaheed List')">
                                            Shuhada Family
                                            &emsp;&emsp;&nbsp;&nbsp;
                                            <label style="color:rgb(85, 205, 162);">{{today_civil}}</label>
                                        </li>
                                    </ul>
                                    <b-img :src="require('@/assets/images/newDashboard/registeration.svg')"
                                           class="congratulation-medal"
                                           width="50px"
                                           alt="Medal Pic" />
                                </div>
                            </b-card>
                        </b-col>
                        <b-col md="6">
                            <b-card class="bg-image shadow" style="margin-bottom: 1%; height:130px">
                                <div style="" @click="getData('today_pending','Today Pending List')">

                                    <h4 style="font-weight:bolder; font-size: 20px; margin-top:13px; ">
                                        <strong>Pending</strong>
                                    </h4>
                                    <h3 class="mb-75 mt-2">
                                        <b-link style="color:rgb(14, 159, 217); font-weight:bolder; font-size: 30px;">{{today_pending}}</b-link>
                                    </h3>
                                    <b-img :src="require('@/assets/images/newDashboard/pending.svg')"
                                           class="congratulation-medal"
                                           alt="Medal Pic"
                                           width="50px" />
                                </div>
                            </b-card>
                            <b-card class="bg-image shadow" style="height: 130px">
                                <div style="">
                                    <h4 style="font-weight:bolder; font-size: 20px; margin-top:13px;">
                                        <strong>Number of Calls</strong>
                                    </h4>
                                    <h3 class="mb-75 mt-2">
                                        <b-link style="color:rgb(233 116 116); font-weight:bolder; font-size: 30px;">{{today_call_count}}</b-link>
                                    </h3>
                                    <b-img :src="require('@/assets/images/newDashboard/call.svg')"
                                           class="congratulation-medal"
                                           alt="Medal Pic"
                                           width="50px" />
                                </div>
                            </b-card>
                        </b-col>
                    </b-row>
                    <b-row class="row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
                        <b-col>
                            <b-card class="bg-image shadow">
                                <div style="" @click="getData('today_approved','Today Approved List')">

                                    <h4 style="font-weight: bolder; font-size: 20px; margin-top: 13px; ">
                                        <strong>Approval</strong>
                                    </h4>
                                    <h3 class="mb-75 mt-2">
                                        <b-link style="color:rgb(85, 205, 162); font-weight:bolder; font-size: 30px;">{{today_approved}}</b-link>
                                    </h3>

                                    <b-img :src="require('@/assets/images/newDashboard/approved 1.svg')"
                                           class="congratulation-medal"
                                           width="50px"
                                           alt="Medal Pic" />
                                </div>
                            </b-card>
                        </b-col>
                        <b-col>
                            <b-card class="bg-image shadow">
                                <div style="" @click="getData('today_rejected', 'Today Rejected List')">

                                    <h4 style="font-weight: bolder; font-size: 20px; margin-top: 13px; ">
                                        <strong>Rejected</strong>
                                    </h4>
                                    <h3 class="mb-75 mt-2">
                                        <b-link style="color:#ff9f43; font-weight:bolder; font-size: 30px;">{{today_rejected}}</b-link>
                                    </h3>
                                    <b-img :src="require('@/assets/images/newDashboard/reject-n 1.svg')"
                                           class="congratulation-medal"
                                           alt="Medal Pic"
                                           width="50px" />
                                </div>
                            </b-card>
                        </b-col>
                        <b-col>
                            <b-card class="bg-image shadow">
                                <div style="">
                                    <h4 style="font-weight: bolder; font-size: 20px; margin-top: 13px;">
                                        <strong>Email forwarded to CORO</strong>
                                    </h4>
                                    <h3 class="mb-75 mt-2">
                                        <b-link style="color:rgb(85, 205, 162); font-weight:bolder; font-size: 30px;">0</b-link>
                                    </h3>
                                    <b-img :src="require('@/assets/images/newDashboard/email.svg')"
                                           class="congratulation-medal"
                                           alt="Medal Pic"
                                           width="50px" />
                                </div>
                            </b-card>
                        </b-col>

                    </b-row>-->
                </b-card>
            </div>

            <!-- <br> -->
            <!-- <b-card class="m-0">

                <h2 style="font-weight: 600; font-size: x-large;"
                    class="text-dark text-center text-uppercase">
                    Summary
                </h2>

                <b-row>

                    <b-col  class="col-md-4 summary">
                        <b-card class="bg-image shadow">
                            <b-col >
                                <h4 style="font-weight: bolder; font-size: 20px; margin-top: 13px; ">
                                    <strong>Bookings</strong>
                                </h4>
                                <ul>
                                    <li class="first" @click="getData('total_pending_booking', 'Pending List')">
                                        Pending &emsp;&emsp;
                                        <label style="font-weight: bolder; color:rgb(14, 159, 217);">
                                            {{total_pending_booking}}
                                        </label>
                                    </li>

                                    <li class="second" @click="getData('total_confirmed_booking', 'Confirmed List')">
                                        Confirmed &emsp;
                                        <label style="font-weight: bolder; color:#ff9f43;">
                                            {{total_confirmed_booking}}
                                        </label>
                                    </li>

                                    <li class="third"  @click="getData('total_booking','All Bookings List')">
                                        Total &emsp;&emsp;&emsp;&emsp;
                                        <label style="font-weight: bolder; color:rgb(85, 205, 162);">
                                            {{total_booking}}
                                        </label>
                                    </li>

                                </ul>


                                <b-img :src="require('@/assets/images/newDashboard/booking.svg')"
                                       class="congratulation-medal"
                                       width="50px"
                                       alt="Medal Pic" />
                            </b-col>
                        </b-card>
                    </b-col>

                    <b-col  class="col-md-4 summary">
                        <b-card class="bg-image shadow">
                            <div id="pageBreakers" ></div>
                            <b-col >
                                <h4 style="font-weight: bolder; font-size: 20px; margin-top: 13px;">
                                    <strong>Petitions</strong>
                                </h4>
                                <ul>
                                    <li class="first" @click="getData('total_pending_petition', 'Pending List')">
                                        Pending &emsp;&emsp;
                                        <label style="font-weight: bolder; color:rgb(14, 159, 217);">
                                            {{total_pending_petition}}
                                        </label>
                                    </li>

                                    <li class="second" @click="getData('total_processed_petition', 'Processed List')">
                                        Processed&emsp;
                                        <label style="font-weight: bolder; color:#ff9f43;">
                                            {{total_processed_petition}}
                                        </label>
                                    </li>

                                    <li class="third"  @click="getData('total_petition', 'All Petitions List')">
                                        Total&emsp;&emsp;&emsp;&emsp;
                                        <label style="font-weight: bolder; color:rgb(85, 205, 162);">
                                            {{total_petition}}
                                        </label>
                                    </li>

                                </ul>

                                <b-img :src="require('@/assets/images/newDashboard/petition.svg')"
                                       class="congratulation-medal"
                                       alt="Medal Pic"
                                       width="50px" />
                            </b-col>
                        </b-card>
                    </b-col>

                    <b-col  class="col-md-4 summary">
                        <b-card class="bg-image shadow">
                            <b-col >
                                <h4 style="font-weight: bolder; font-size: 20px; margin-top: 13px;">
                                    <strong>NOCs</strong>
                                </h4>
                                <ul>
                                    <li class="first" @click="getData('total_pending_noc', 'Pending List')">
                                        Pending &emsp;&emsp;
                                        <label style="font-weight: bolder; color:rgb(14, 159, 217);">
                                            {{total_pending_noc}}
                                        </label>
                                    </li>

                                    <li class="second" @click="getData('total_processed_noc', 'Processed List')">
                                        Processed&emsp;
                                        <label style="font-weight: bolder; color:#ff9f43;">
                                            {{total_processed_noc}}
                                        </label>
                                    </li>

                                    <li class="third"  @click="getData('total_noc', 'All NOCs List')">
                                        Total&emsp;&emsp;&emsp;&emsp;
                                        <label style="font-weight: bolder; color:rgb(85, 205, 162);">
                                            {{total_noc}}
                                        </label>
                                    </li>

                                </ul>

                                <b-img :src="require('@/assets/images/newDashboard/NOC.svg')"
                                       class="congratulation-medal"
                                       alt="Medal Pic"
                                       width="50px" />
                            </b-col>
                        </b-card>
                    </b-col>
                    <b-col md="4">
                        <b-card class="bg-image shadow">
                            <b-col>
                                <h4 style="font-weight: bolder; font-size: 20px; margin-top: 13px; ">
                                    <strong>Emails Forwarded to CORO</strong>
                                </h4>
                                <h3 class="mb-75 mt-2">
                                    <b-link style="color:rgb(19 196 197); font-weight:bolder; font-size: 30px;">{{total_email_count}}</b-link>
                                </h3>

                                <b-img :src="require('@/assets/images/newDashboard/email.svg')"
                                       class="congratulation-medal"
                                       width="50px"
                                       alt="Medal Pic" />
                            </b-col>
                        </b-card>
                    </b-col>
                </b-row>
            </b-card>
            <br> -->
            <!-- <b-card class="m-0">
                <h2 style="font-weight: 600; font-size: x-large;"
                    class="text-dark text-center text-uppercase">
                    Today's Statistics
                </h2>
                <b-row>
                    <b-col md="4">
                        <b-card class="bg-image shadow">
                            <b-col @click="getData('today_booking', 'Today Booking List')">

                                <h4 style="font-weight: bolder; font-size: 20px; margin-top: 13px; ">
                                    <strong>Bookings</strong>
                                </h4>
                                <h3 class="mb-75 mt-2">
                                    <b-link style="color:rgb(14, 159, 217); font-weight:bolder; font-size: 30px;">{{today_booking}}</b-link>
                                </h3>

                                <b-img :src="require('@/assets/images/newDashboard/booking.svg')"
                                       class="congratulation-medal"
                                       width="50px"
                                       alt="Medal Pic" />
                            </b-col>
                        </b-card>
                    </b-col>
                    <div class="breaker"></div>

                    <b-col md="4">
                        <b-card class="bg-image shadow">
                            <b-col @click="getData('today_petition', 'Today Petition List')">
                                <h4 style="font-weight: bolder; font-size: 20px; margin-top: 13px;">
                                    <strong>Petitions</strong>
                                </h4>
                                <h3 class="mb-75 mt-2">
                                    <b-link style="color:#e18e3d; font-weight:bolder; font-size: 30px;">{{today_petition}}</b-link>
                                </h3>
                                <b-img :src="require('@/assets/images/newDashboard/petition.svg')"
                                    class="congratulation-medal"
                                    alt="Medal Pic"
                                    width="50px" />
                            </b-col>
                        </b-card>
                    </b-col>
                    <div class="breaker"></div>
                    <b-col md="4">
                        <b-card class="bg-image shadow">
                            <b-col @click="getData('today_noc', 'Today NOC List')">
                                <h4 style="font-weight: bolder; font-size: 20px; margin-top: 13px;">
                                    <strong>NOCs</strong>
                                </h4>
                                <h3 class="mb-75 mt-2">
                                    <b-link style="color:rgb(85, 205, 162); font-weight:bolder; font-size: 30px;">{{today_noc}}</b-link>
                                </h3>
                                <b-img :src="require('@/assets/images/newDashboard/NOC.svg')"
                                    class="congratulation-medal"
                                    alt="Medal Pic"
                                    width="50px" />
                            </b-col>
                        </b-card>
                    </b-col>
                </b-row>
            </b-card> -->

            <br>
            <b-overlay :show="show"
                       spinner-variant="primary"
                       no-wrap />

            <view-filtered-user-modal :heading="heading"
                                      @modalClosed="onModalClosed"
                                      :id="`view-filtered-user-modal-total`"
                                      :obj_for_filtering="obj_for_filtering"
                                      :key="`view_total-${viewModalCount}`"
                                      :users="users" />
            <view-filtered-booking-modal :heading="heading"
                                         :id="`view-filtered-booking-modal`"
                                         :key="`view_total_booking-${viewModalCount}`"
                                         :users="users" />
            <view-filtered-petition-modal :heading="heading"
                                          :type_="type_"
                                          :id="`view-filtered-petition-modal`"
                                          :key="`view_total_petition-${viewModalCount}`"
                                          :users="users" />
            <view-filtered-noc-modal :heading="heading"
                                     :id="`view-filtered-noc-modal`"
                                     :key="`view_total_noc-${viewModalCount}`"
                                     :users="users" />
            <report-dashboard-modal id="report-dashboard-modal"
                                    :key="`view_report_dashboard-${reportCount}`"
                                    :report_data="report_data" />

            <br>

        </div>
        <!-- <div class="d-flex justify-content-center align-items-center flex-nowrap mr-2">
            <b-button variant="primary" pill @click="downloadPDF">
            <feather-icon icon="DownloadIcon" class="mr-50" />
            <span class="align-middle">Download PDF</span>
            </b-button>
        </div>
        <br> -->
    </div>
</template>

<script>
    import { BCard, BCardText, BLink, BIconCardImage } from "bootstrap-vue";
    import util from "../../util";
    import { mapActions, mapGetters } from "vuex";
    import TotalSummaryLineChart from "./charts/TotalSummaryLineChart.vue";
    import TodaySummaryLineChart from "./charts/TodaySummaryLineChart.vue";
    import Navbar from "./charts/Navbar.vue";
    import ViewFilteredUserModal from "./charts/ViewFilteredUserModal.vue";
    import ViewFilteredBookingModal from "./charts/ViewFilteredBookingModal.vue";
    import ViewFilteredComplaintModal from "./charts/ViewFilteredComplaintModal.vue";
    import ViewFilteredPetitionModal from "./charts/ViewFilteredPetitionModal.vue";
    import ViewFilteredNocModal from "./charts/ViewFilteredNocModal.vue";
    import callSummaryLineChart from "./charts/callSummaryLineChart.vue";
    import TodayRegistrationPieChart from "./charts/TodayRegistrationPieChart.vue";
    import html2pdf from "html2pdf.js";
    import reportDashboardModal from "./charts/reportDashboardModal.vue";
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {
        components: {
            BCard,
            BCardText, Navbar, TodayRegistrationPieChart,
            BLink,
            TotalSummaryLineChart, TodaySummaryLineChart, ViewFilteredUserModal,
            ViewFilteredBookingModal, ViewFilteredComplaintModal, ViewFilteredPetitionModal, ViewFilteredNocModal, callSummaryLineChart, reportDashboardModal,
            ToastificationContent
        },
        mixins: [util],
        props: {
            show_data: Boolean,
        },
        data() {
            return {
                show: false,
                awardImageCount: 0,
                armylogo: require("@/assets/images/default/logo.png"),
                graphCount: 0,

                total_user: 0,
                total_approved: 0,
                total_approved_serving: 0,
                total_approved_retired: 0,
                total_approved_civil: 0,
                total_rejected: 0,
                total_pending: 0,

                today_user: 0,
                today_approved: 0,
                today_rejected: 0,
                today_pending: 0,

                total_summary: [],
                today_summary: [],
                viewModalCount: 0,
                users: null,
                total_serving_user: 0,
                total_retired_user: 0,
                total_civil_user: 0,
                total_booking: 0,
                total_complaint: 0,
                total_petition: 0,
                total_noc: 0,

                today_booking: 0,
                today_complaint: 0,
                today_petition: 0,
                today_noc: 0,
                today_call_count: 0,
                total_call_count: 0,
                user_data: [],
                heading: "",
                type_: "",

                obj_for_filtering: null,
                today_serving: 0,
                today_retired: 0,
                today_civil: 0,
                total_user_last_week: null,
                total_summary_line_chart_data: null,

                total_pending_booking: null,
                total_confirmed_booking: null,
                total_canceled_booking: null,

                total_pending_petition: null,
                total_processed_petition: null,

                total_pending_noc: null,
                total_processed_noc: null,
                reportCount: 0,
                report_data: null,
                total_email_count: 0,
                items: [
                    'First item',
                    'Second item',
                    'Third item',
                    'Fourth item',
                ],
                today_data: [],
                timeouts: [],
                total_sms: 0,
                total_booking_sms: 0,
                today_sms: 0,
                today_booking_sms: 0, 
            };
        },
        created() {
            // this.showToast("info");
            this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true);

        },
        destroyed() {
            this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', this.isVerticalMenuCollapsed)

            for (let i = 0; i < this.timeouts.length; i++) {
                clearTimeout(this.timeouts[i]);
            }

        },
        async mounted() {
            await this.retrieveDashboardData();
            if (this.hasRole('su') || this.hasRole('op') || this.hasRole('op_admin')) {
                this.showToast("warning");
            }

        },
        methods: {
            ...mapActions({
                getDashboardData: "appData/getDashboardData",
                getAdminFilteredDashboardData: "appData/getAdminFilteredDashboardData",
            }),
            showToast(variant) {
                let delay = 0;
                for (let i = 0; i < this.today_data.length; i++) {
                    const timeout = setTimeout(() => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Approval State',
                                icon: 'BellIcon',
                                text: "ID: " + this.today_data[i].id + ", " + this.today_data[i].name + " Is Approved",
                                variant,
                                TimeRange: 4000
                            }
                        });
                    }, delay);
                    this.timeouts.push(
                        timeout
                    );
                    delay += 10000;
                }
            },

            async downloadPDF() {
                try {
                    this.show = true;
                    const element = document.getElementById('dashboard');
                    if (element && element.innerHTML) {

                        const total_summary = element.querySelectorAll('.total_summary');
                        total_summary.forEach(col => {
                            col.classList.remove('col-md-5', 'hr');
                            col.classList.add('col-md-12', 'd-inline', 'mb-3');
                        });

                        const hrsToRemove = element.querySelectorAll('.break-hr');
                        const parentNodes = Array.from(hrsToRemove, hr => hr.parentNode);
                        hrsToRemove.forEach(hr => hr.parentNode.removeChild(hr));

                        const hre = element.querySelectorAll('.hre');
                        hre.forEach(col => {
                            col.classList.add('border', 'border-dark');
                        });

                        const SecondColumn = element.querySelectorAll('.col-md-2');
                        SecondColumn.forEach(col => {
                            col.classList.remove('col-md-2', 'seperator');
                            col.classList.add('col-md-12');
                        });

                        const today_summary = element.querySelectorAll('.today_summary');
                        today_summary.forEach(col => {
                            col.classList.remove('col-md-5');
                            col.classList.add('col-md-12', 'd-flex', 'justify-content-center', 'mb-5');
                        });

                        const today_registration_pie = element.querySelectorAll('.today_registration_pie');
                        today_registration_pie.forEach(col => {
                            col.classList.remove('col-md-6');
                            col.classList.add('col-md-12', 'd-inline');
                        });

                        const today_approved = element.querySelectorAll('.today_approved');
                        today_approved.forEach(col => {
                            col.classList.remove('col-md-6', 'seperator_today');
                            col.classList.add('col-md-12', 'mb-5');
                        });

                        const summary = element.querySelectorAll('.summary');
                        summary.forEach(col => {
                            col.classList.remove("col-md-4");
                            col.classList.add("col-md-6");
                        });

                        const options = {
                            filename: 'AFPDashboardReport.pdf',
                            image: { type: 'jpg', quality: 0.95 },
                            html2canvas: { scale: 4, dpi: 192, letterRendering: true, useCORS: true },
                            jsPDF: { unit: 'pt', format: 'A4', orientation: 'portrait' }
                        };

                        await html2pdf().from(element).set(options).save();

                        total_summary.forEach(col => {
                            col.classList.remove('col-md-12', 'd-inline');
                            col.classList.add('col-md-5', 'hr');
                        });
                        SecondColumn.forEach(col => {
                            col.classList.remove('col-md-12');
                            col.classList.add('col-md-2', 'seperator');
                        });
                        today_summary.forEach(col => {
                            col.classList.remove('col-md-12', 'd-flex', 'justify-content-center');
                            col.classList.add('col-md-5');
                        });
                        today_registration_pie.forEach(col => {
                            col.classList.remove('col-md-12', 'd-inline');
                            col.classList.add('col-md-6',);
                        });
                        today_approved.forEach(col => {
                            col.classList.remove('col-md-12');
                            col.classList.add('col-md-6', 'seperator_today');
                        });
                        summary.forEach(col => {
                            col.classList.remove("col-md-6", 'mb-5');
                            col.classList.add("col-md-4");
                        });
                        hre.forEach(col => {
                            col.classList.remove('border', 'border-dark');
                        });

                        hrsToRemove.forEach((hr, index) => {
                            parentNodes[index].appendChild(hr);
                        });

                        this.show = false;

                    } else {
                        console.error('Dashboard content not available.');
                    }
                } catch (error) {
                    console.error("Error generating PDF:", error);
                }
            },


            async getData(data, heading) {

                var obj = null;
                this.heading = heading;
                if (data === 'total_user') {
                    try {
                        this.show = true;
                        obj = {
                            type: data,
                            status: ""
                        };
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.obj_for_filtering = obj;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-user-modal-total");
                        });
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                } else if (data === 'total_approved') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'total_summary',
                            status: "Approved"
                        };
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.obj_for_filtering = obj;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-user-modal-total");
                        });
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'total_rejected') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'total_summary',
                            status: "Rejected"
                        };
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.obj_for_filtering = obj;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-user-modal-total")
                        });
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'total_pending') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'total_summary',
                            status: "Pending"
                        };
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.obj_for_filtering = obj;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-user-modal-total")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'today_user') {
                    try {
                        this.show = true;
                        obj = {
                            type: data,
                            status: ""
                        };
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.obj_for_filtering = obj;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-user-modal-total")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'today_approved') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'today_summary',
                            status: "Approved"
                        };
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.obj_for_filtering = obj;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-user-modal-total")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'today_rejected') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'today_summary',
                            status: "Rejected"
                        };
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.obj_for_filtering = obj;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-user-modal-total")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'today_pending') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'today_summary',
                            status: "Pending"
                        };
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.obj_for_filtering = obj;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-user-modal-total")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'Serving') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'Serving',
                            status: ""
                        };
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.obj_for_filtering = obj;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-user-modal-total")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'Retired') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'Retired',
                            status: ""
                        };
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.obj_for_filtering = obj;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-user-modal-total")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'Civil') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'Civil',
                            status: ""
                        };
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.obj_for_filtering = obj;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-user-modal-total")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'approved_serving') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'approved_serving',
                            status: ""
                        };
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.obj_for_filtering = obj;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-user-modal-total")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'approved_retired') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'approved_retired',
                            status: ""
                        };
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.obj_for_filtering = obj;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-user-modal-total")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'approved_civil') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'approved_civil',
                            status: ""
                        };
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.obj_for_filtering = obj;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-user-modal-total")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'total_booking') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'total_booking',
                            status: ""
                        };
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-booking-modal")
                        })
                        this.show = false;
                    }
                    catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'total_complaint') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'total_complaint',
                            status: ""
                        };
                        this.type_ = "COMPLAINT"
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-petition-modal")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'total_petition') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'total_petition',
                            status: ""
                        };
                        this.type_ = "PETITION"
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-petition-modal")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'total_noc') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'total_noc',
                            status: ""
                        };
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-noc-modal")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'today_booking') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'today_booking',
                            status: ""
                        };
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-booking-modal")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'today_complaint') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'today_complaint',
                            status: ""
                        };
                        this.type_ = "COMPLAINT"
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-petition-modal")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'today_petition') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'today_petition',
                            status: ""
                        };
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.type_ = "PETITION"
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-petition-modal")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data === 'today_noc') {
                    try {
                        this.show = true;
                        obj = {
                            type: 'today_noc',
                            status: ""
                        };
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-noc-modal")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data == 'today_serving') {
                    try {
                        this.show = true;
                        obj = {
                            type: "today_serving",
                            status: ""
                        }
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-user-modal-total")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data == 'today_retired') {
                    try {
                        this.show = true;
                        obj = {
                            type: "today_retired",
                            status: ""
                        }
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-user-modal-total")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data == 'today_civil') {
                    try {
                        this.show = true;
                        obj = {
                            type: "today_civil",
                            status: ""
                        }
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-user-modal-total")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data == 'total_pending_booking') {
                    try {
                        this.show = true;
                        obj = {
                            type: "total_pending_booking",
                            status: ""
                        }
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-booking-modal")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data == 'total_confirmed_booking') {
                    try {
                        this.show = true;
                        obj = {
                            type: "total_confirmed_booking",
                            status: ""
                        }
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-booking-modal")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data == 'total_pending_petition') {
                    try {
                        this.show = true;
                        obj = {
                            type: "total_pending_petition",
                            status: ""
                        }
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.type_ = "PETITION"
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-petition-modal")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data == 'total_processed_petition') {
                    try {
                        this.show = true;
                        obj = {
                            type: "total_processed_petition",
                            status: ""
                        }
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.type_ = "PETITION"
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-petition-modal")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                // view-filtered-noc-modal
                else if (data == 'total_pending_noc') {
                    try {
                        this.show = true;
                        obj = {
                            type: "total_pending_noc",
                            status: ""
                        }
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-noc-modal")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
                else if (data == 'total_processed_noc') {
                    try {
                        this.show = true;
                        obj = {
                            type: "total_processed_noc",
                            status: ""
                        }
                        const _res = await this.getAdminFilteredDashboardData(obj);
                        this.users = _res.data.data.users;
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-filtered-noc-modal")
                        })
                        this.show = false;
                    } catch (error) {
                        this.show = false;
                        this.displayError(error);
                    }
                }
            },
            async retrieveDashboardData() {
                try {
                    this.show = true;
                    const _res = await this.getDashboardData();
                    this.graphCount += 1;
                    this.user_data = _res.data.user_data;
                    this.total_user = this.user_data[0].total_user.toLocaleString();
                    this.total_approved = this.user_data[0].total_approved.toLocaleString();
                    this.total_approved_serving = this.user_data[0].total_approved_serving.toLocaleString();
                    this.total_approved_retired = this.user_data[0].total_approved_retired.toLocaleString();
                    this.total_approved_civil = this.user_data[0].total_approved_civil.toLocaleString();
                    this.total_rejected = this.user_data[0].total_rejected.toLocaleString();
                    this.total_pending = this.user_data[0].total_pending.toLocaleString();

                    this.today_user = this.user_data[0].today_user.toLocaleString();
                    this.today_approved = this.user_data[0].today_approved.toLocaleString();
                    this.today_rejected = this.user_data[0].today_rejected.toLocaleString();
                    this.today_pending = this.user_data[0].today_pending.toLocaleString();

                    this.total_serving_user = this.user_data[0].total_serving_user.toLocaleString();
                    this.total_retired_user = this.user_data[0].total_retired_user.toLocaleString();
                    this.total_civil_user = this.user_data[0].total_civil_user.toLocaleString();
                    this.total_booking = this.user_data[0].total_booking.toLocaleString();
                    this.total_complaint = this.user_data[0].total_complaint.toLocaleString();
                    this.total_petition = this.user_data[0].total_petition.toLocaleString();
                    this.total_noc = this.user_data[0].total_noc.toLocaleString();
                    this.total_call_count = this.user_data[0].total_call_count.toLocaleString();

                    this.today_booking = this.user_data[0].today_booking.toLocaleString();
                    this.today_complaint = this.user_data[0].today_complaint.toLocaleString();
                    this.today_petition = this.user_data[0].today_petition.toLocaleString();
                    this.today_noc = this.user_data[0].today_noc.toLocaleString();

                    this.total_summary = this.user_data[0].total_summary;
                    this.today_summary = this.user_data[0].today_summary;
                    this.today_call_count = this.user_data[0].today_call_count.toLocaleString();

                    this.today_serving = this.user_data[0].today_serving.toLocaleString();
                    this.today_retired = this.user_data[0].today_retired.toLocaleString();
                    this.today_civil = this.user_data[0].today_civil.toLocaleString();

                    //this.total_user_last_week = this.user_data[0].total_user_last_week;
                    this.total_summary_line_chart_data = this.user_data[0].total_summary_line_chart_data;

                    this.total_pending_booking = this.user_data[0].total_pending_booking.toLocaleString();
                    this.total_confirmed_booking = this.user_data[0].total_confirmed_booking.toLocaleString();
                    this.total_canceled_booking = this.user_data[0].total_canceled_booking.toLocaleString();

                    this.total_pending_petition = this.user_data[0].total_pending_petition.toLocaleString();
                    this.total_processed_petition = this.user_data[0].total_processed_petition.toLocaleString();

                    this.total_pending_noc = this.user_data[0].total_pending_noc.toLocaleString();
                    this.total_processed_noc = this.user_data[0].total_processed_noc.toLocaleString();
                    this.total_email_count = this.user_data[0].total_email_count.toLocaleString();
                    this.today_data = this.user_data[0].today_data;
                    this.total_sms = this.user_data[0].total_sms;
                    this.total_booking_sms = this.user_data[0].total_booking_sms;
                    this.today_sms = this.user_data[0].today_sms;
                    this.today_booking_sms = this.user_data[0].today_booking_sms;

                    this.show = false;
                } catch (error) {
                    this.show = false;
                    this.displayError(error);
                }
            },
            generateReportModal() {
                this.report_data = this.user_data[0];
                this.reportCount += 1;
                this.$nextTick(() => {
                    this.$bvModal.show("report-dashboard-modal");
                });
            },
            async onModalClosed() {
                if (this.obj_for_filtering.status == "Pending") {
                    await this.retrieveDashboardData();
                }
            },

        },
        computed: {
            ...mapGetters({
                hasPermission: "appData/hasPermission",
                hasRole: "appData/hasRole",
            }),
        }
    };
</script>

<style scoped>
    .background {
        background: rgba(9, 65, 32, 0.7) url("~@/assets/images/banner.png");
        background-size: cover;
        background-position: center;
    }
    /* .backgroundcard{

    } */
    .bg-image {
        background-image: url('~@/assets/images/newDashboard/Group 1000000833.png');
        background-size: cover;
        background-position: center;
        /* cursor: pointer; */
    }

    .bg-image-1 {
        background-image: url('~@/assets/images/newDashboard/Group 1000000833.png');
        background-size: cover;
        background-position: center;
    }

    .registrationImg {
        background-image: url('~@/assets/images/newDashboard/Group 1000000833.png');
        background-size: cover;
        background-position: center;
    }

    .fs-image {
        background-image: url('~@/assets/images/newDashboard/Group 1000000833.png');
        background-size: cover;
        background-position: center;
    }

    .congratulation-medal {
        top: 25px;
        left: 83%;
    }

    .shadow {
        box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
        -webkit-box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
        -moz-box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
    }

    .bg-img {
        background-image: url('~@../../../public/afp-logo.png');
        background-size: cover;
        background-position: center;
    }

    .first {
        /* list-style-type: none; */
        font-size: 20px;
        /* font-weight: bolder; */
        cursor: pointer;
    }
    .second {
        /* list-style-type: none; */
        font-size: 20px;
        /* font-weight: bolder; */
        cursor: pointer;
    }
    .third {
        /* list-style-type: none; */
        font-size: 20px;
        /* font-weight: bolder; */
        cursor: pointer;
    }

        /* li::marker {
            color: rgb(233 116 116);
            font-size: 25px;
        } */


    .second::marker {
        color: rgb(85 205 162);
    }

    .approved_text {
        color: rgb(85 205 162);
        font-size: 16px;
    }

    .summary_value {
        font-size: 18px;
        color: rgb(233 116 116);
    }

    .child {
        width: 49%;
    }

    @media screen and (max-width: 700px) {
        .child {
            width: 100%;
        }
    }
    /* .row{
        margin-bottom: 0%!important;
    } */
    .no-gap {
        margin-bottom: 0 !important;
        /* padding-bottom: 0 !important; */
    }
    /* .no-gap-row {
        margin-bottom: 0 !important;
    }
    .no-gap-col {
        padding-bottom: 0 !important;
    } */
    .first::marker {
        color: rgb(14, 159, 217);
        font-size: 25px;
        font-weight: bolder;
    }

    .second::marker {
        color: #ff9f43;
        font-size: 25px;
        font-weight: bolder;
    }

    .third::marker {
        color: rgb(85, 205, 162);
        font-size: 25px;
        font-weight: bolder;
    }

    @media screen and (max-width: 767px) {
        .seperator {
            border-top: 1px solid #413f3f;
            border-bottom: 1px solid #413f3f;
            border-right: none !important;
            border-left: none !important;
            margin-top: 20px;
            margin-bottom: 30px;
        }

        .seperator_today {
            border-top: 1px solid #413f3f;
            border-bottom: 1px solid #413f3f;
            border-right: none !important;
            border-left: none !important;
            margin-top: 20px;
            margin-bottom: 30px;
        }
    }

    .seperator_today {
        border-right: none !important;
        border-left: 1px solid #413f3f;
    }

    .seperator {
        border-right: 1px solid #413f3f;
        border-left: 1px solid #413f3f;
    }

    /* .hrr {
        border: 0;
        clear: both;
        display: block;
        width: 96%;
        background-color: #413f3fa2;
        height: 1px;
    } */
    hr {
        border: 0;
        width: 96%;
        background-color: #413f3fa2;
        height: 1px;
    }

    .card-body {
        padding-bottom: 0.5rem !important;
    }
    /* .hr-top{
        margin-top: 15px!important;
        border: 0;
        background-color: #413f3fa2;
        height: 1px;
    } */
    /* .break-hr{
        margin-top:35px;
        border: 0;
        width: 96%;
        background-color: #413f3fa2;
        height: 1px;
    } */
    .gap-right {
        margin-right: 10px; /* Adjust this value as needed */
    }
    .flex-container {
        display: flex;
    }
    .inline-ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    .recruitment_div{
        padding:0 0 0 5px !important;
    }
    .course_list_first::marker{
        color: rgb(14, 159, 217);
        
    }
    .course_list_second::marker{
        color: #ff9f43;
    }
    .course_list_third::marker{
        color: rgb(85, 205, 162);
    }
</style>