<template>
    <div>
        <b-card class="bg-image shadow border-0">
            
                <!-- <vue-apex-charts type="bar"
                                 height="400"
                                 :options="lineChartSimple.chartOptions"
                                 @dataPointSelection="clickHandler"
                                 :series="lineChartSimple.series" /> -->
                <b-row>
                <!-- <b-col md="5">
                    <h3 style="font-weight: bold">Today's Statistics</h3>
                        <br>
                        <ul>
                            <li class="first">Pending</li>
                            <label >{{today_pending}}</label>
                            <li class="second">Rejected</li>
                            <label >{{today_rejected}}</label>
                            <li class="third">Approved</li>
                            <label >{{today_approved}}</label>
                        </ul>
                </b-col> -->
                <b-col>    
                <vue-apex-charts type="line"
                                 height="255"
                                 @dataPointSelection="clickHandler"
                                 :options="donutChart.chartOptions"
                                 :series="donutChart.series" />
                </b-col>
           </b-row>
        </b-card>

        <b-overlay :show="show"
                   spinner-variant="primary"
                   no-wrap />
        <view-filtered-user-modal :id="`view-today-filtered-user-modal`"
                                  :key="`view_total-${viewModalCount}`"
                                  :users="users" />
    </div>

</template>

<script>
    import {
        BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge,
    } from 'bootstrap-vue'
    import VueApexCharts from 'vue-apexcharts'
    import { $themeColors } from '@themeConfig'
    import { mapActions, mapGetters } from "vuex";
    import util from "../../../util";
    import ViewFilteredUserModal from "./ViewFilteredUserModal.vue";

    export default {
        components: {
            ViewFilteredUserModal,
            VueApexCharts,
            BCardHeader,
            BCard,
            BBadge,
            BCardBody,
            BCardTitle,
            BCardSubTitle,
        },
        props: {
            details: Array,
            data:Array
        },
        mixins: [util],
        data() {
            return {
                donutChart: {
                   series: [{
                    name: "Desktops",
                    data: [2,21,34,42,32,53,43]
                    }],
                    chartOptions: {
                        legend: {
                            show: true,
                            position: 'bottom',
                            fontSize: '14px',
                            fontFamily: 'Montserrat',
                        },
                        colors: [
                           
                           '#2bdac7',
                        ],
                        title: {
                            text: "Statistics",
                            margin: 10,
                            offsetX: 0,
                            offsetY: 0,
                            floating: false,
                            style: {
                                fontSize: '25px',
                                fontWeight: 'bolder',
                                color: '#535858'
                            },
                        },
                        dataLabels: {
                            enabled: false,
                            // formatter(val) {
                            //     return `${parseInt(val)}`;
                            // },
                        },
                        plotOptions: {
                            pie: {
                                donut: {
                                    labels: {
                                        show: true,
                                        name: {
                                            fontSize: '2rem',
                                            fontFamily: 'Montserrat',
                                        },
                                        value: {
                                            fontSize: '1rem',
                                            fontFamily: 'Montserrat',
                                            // formatter(val) {
                                            //     // eslint-disable-next-line radix
                                            //     return `${parseInt(val)}`
                                            // },
                                        },
                                        total: {
                                            show: false,
                                            fontSize: '1.5rem',
                                            label: 'Total',
                                            // formatter(series) {
                                            //     const total = series.config.series.reduce((acc, val) => acc + val, 0);
                                            //     return total.toString();
                                            // },
                                        },
                                    },
                                },
                            },
                        },
                        
                    },
                },
               
                data_array: [],
                searching_criteria: {},
                users: null,
                show: false,
                viewModalCount: 0,
                today_pending:0,
                today_approved:0,
                today_rejected:0,
            }
        },
        async created() {
            if (this.details) {
                // console.log("jhaskjhdaskjshkjhdaskj",this.details)
                // this.data_array = this.details;
                // this.donutChart.series[0].data = this.data_array;
                // console.log("linechart",this.donutChart.series[0].data = this.data_array)
                // this.donutChart.series[0].data = this.data_array;
            }
        },
        async mounted() {
           
            if(this.data.length>0 && this.data){
                    this.today_approved = this.data[0].today_approved;
                    this.today_rejected = this.data[0].today_rejected;
                    this.today_pending = this.data[0].today_pending;
                }
            // await this.retrieveDashboardData();
        },
        methods: {
            ...mapActions({
                getAdminFilteredDashboardData: "appData/getAdminFilteredDashboardData",
                // getDashboardData: "appData/getDashboardData",
            }),
            async onModalClosed() {
            },
            async clickHandler(event, chartContext, config) {
             
                let selectedDonut = config.dataPointIndex;
                let selectedItem = config.w.config.labels[selectedDonut];
                try {
                    this.show = true;
                    this.searching_criteria.type = 'today_summary';
                    this.searching_criteria.status = selectedItem;
                    const res = await this.getAdminFilteredDashboardData(this.searching_criteria);
                    this.users = res.data.data.users;
                    this.show = false;
                    if (this.users.length > 0) {
                        this.viewModalCount += 1;
                        this.$nextTick(() => {
                            this.$bvModal.show("view-today-filtered-user-modal");
                        });
                    }
                } catch (error) {
                    this.show = false;
                    this.displayError(error);
                }
            },
            // async retrieveDashboardData() {
            //     try {
            //         this.show = true;
            //         const _res = await this.getDashboardData();
            //         this.graphCount += 1;
            //         this.total_user = _res.data.total_user.toLocaleString();
            //         this.total_approved = _res.data.total_approved.toLocaleString();
            //         this.total_rejected = _res.data.total_rejected.toLocaleString();
            //         this.total_pending = _res.data.total_pending.toLocaleString();

            //         this.today_user = _res.data.today_user.toLocaleString();
            //         this.today_approved = _res.data.today_approved.toLocaleString();
            //         this.today_rejected = _res.data.today_rejected.toLocaleString();
            //         this.today_pending = _res.data.today_pending.toLocaleString();

            //         this.total_summary = _res.data.total_summary;
            //         this.today_summary = _res.data.today_summary;

            //         this.show = false;
            //     } catch (error) {
            //         this.show = false;
            //         this.displayError(error);
            //     }
            // },
            
        },
        ...mapGetters({
            hasPermission: "appData/hasPermission",
        }),
    };
</script>
<style scoped>
    .bg-image {
    background-image: url('~@/assets/images/newDashboard/Background-1.png');
    background-size: cover;
    background-position: center;
    
    }
    .shadow{
    box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
    -webkit-box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
    -moz-box-shadow: 8px 8px 44px -9px rgba(0,0,0,0.35) !important;
   
    }  
    .first::marker {
    color:rgb(14, 159, 217);
    font-size: 25px;
    }
    .second::marker {
    color:rgb(233, 116, 116);
    font-size: 25px;
    }
    .third::marker {
    color:rgb(85, 205, 162);
    font-size: 25px;
    }

    li{
        font-size: 18px;
    }
    label{
        font-size: 18px;
        font-weight: bolder;
        color:black;
    }
</style>