<template>
    <b-modal :id="id"
             title="Rank Edit Modal"
             centered
             hide-footer
             size="xl"
             @hidden="reset"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true"
             hideHeaderClose>
        <template #modal-title>
            <!--<h2 class="m-0">{{heading}}</h2>-->
        </template>
        <b-row>
            <b-col style="text-align:center">
                <h1 class="m-0 font-weight-bold mb-1">
                    <u>{{heading}}</u>
                </h1>
            </b-col>
            <b-col md="2">
                <b-button variant="primary"
                          @click="closeModal">
                    <feather-icon icon="XIcon" class="mr-50" />
                    Close
                </b-button>
            </b-col>
        </b-row>
        <b-table v-if="rejected"
                 responsive="sm"
                 :fields="rejected_fields"
                 :items="user_array"
                 :paginated="true"
                 details-td-class="m-0 p-0"
                 small>
            <template #cell(index)="row">
                {{row.index + 1}}.
            </template>
            <template #cell(is_approved)="row">
                {{row.item.is_approved === 1 ? "Approved" : row.item.is_approved === 2 ? "Rejected" : "Processing"}}
            </template>
            <template #cell(user_type)="row">
                {{ getTypeValue(row.item.user_type)}}
            </template>
        </b-table>
        <b-table v-else-if="pending"
                 responsive="sm"
                 :fields="pending_fields"
                 :items="user_array"
                 :paginated="true"
                 details-td-class="m-0 p-0"
                 small>
            <template #cell(index)="row">
                {{row.index + 1}}.
            </template>
            <template #cell(user_calls)="row">
                {{row.item.user_calls}} &nbsp;
                <b-button style="background-color: #71713e !important; border-color: #71713e !important; "
                          pill
                          size="sm"
                          @click="updateCallCount(row.item)"
                          v-if="row.item.is_approved == 3">
                    Update
                </b-button>
            </template>
            <template #cell(is_approved)="row">
                {{row.item.is_approved === 1 ? "Approved" : row.item.is_approved === 2 ? "Rejected" : "Processing"}}
            </template>
            <template #cell(user_type)="row">
                {{ getTypeValue(row.item.user_type)}}
            </template>
        </b-table>
        <b-table v-else
                 responsive="sm"
                 :fields="fields"
                 :items="user_array"
                 :paginated="true"
                 details-td-class="m-0 p-0"
                 small>
            <template #cell(index)="row">
                {{row.index + 1}}.
            </template>
            <template #cell(user_calls)="row">
                {{row.item.user_calls}} &nbsp;
                <b-button style="background-color: #71713e !important; border-color: #71713e !important; "
                          pill
                          size="sm"
                          @click="updateCallCount(row.item)"
                          v-if="row.item.is_approved == 3">
                    Update
                </b-button>
            </template>
            <template #cell(approved_by)="row">
                {{ row.item.is_approved == 3 ? 'Pending' : row.item.approved_by}}
            </template>
            <template #cell(user_type)="row">
                {{ getTypeValue(row.item.user_type)}}
            </template>
            <template #cell(is_approved)="row">
                {{row.item.is_approved === 1 ? "Approved" : row.item.is_approved === 2 ? "Rejected" : "Processing"}}
            </template>
        </b-table>

        <b-overlay :show="show"
                   spinner-variant="primary"
                   no-wrap />
    </b-modal>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from "vee-validate";
    import { required, email } from "@validations";
    import util from "../../../util";
    import { mapActions, mapGetters } from "vuex";
    import Ripple from 'vue-ripple-directive'
    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        props: {
            users: Array,
            id: String,
            heading: String,
            obj_for_filtering: Object,
        },
        directives: {
            Ripple,
        },
        mixins: [util],
        data() {
            return {
                name: null,
                required,
                email,
                show: false,
                fields: [
                    {
                        key: "index",
                        label: "Sr.",
                    },
                    { key: "id", label: "Registration ID" },
                    { key: "name", label: "Name" },
                    { key: "cnic", label: "CNIC" },
                    { key: "mobile", label: "Mobile" },
                    /*{ key: "user_type", label: "User Type" },*/
                    { key: "created_at", label: "Registration Date" },
                    { key: "approved_at", label: "Approval Date" },
                    { key: "approved_by", label: "Approved By" },
                    /*       { key: "is_approved", label: "Approval Status" },*/
                    { key: "user_calls", label: "call count" },
                    { key: "user_email_sent", label: "Email Sent Count" },
                ],
                pending_fields: [
                    {
                        key: "index",
                        label: "Sr.",
                    },
                    { key: "id", label: "Registration ID" },
                    { key: "name", label: "Name" },
                    { key: "cnic", label: "CNIC" },
                    { key: "mobile", label: "Mobile" },
                    /*{ key: "user_type", label: "User Type" },*/
                    { key: "created_at", label: "Registration Date" },
                    //{ key: "approved_at", label: "Pending Date" },
                    //{ key: "approved_by", label: "Pending By" },
                    /*       { key: "is_approved", label: "Approval Status" },*/
                    { key: "pending_reason", label: "Pending Reason", variant: 'warning' },
                    { key: "user_calls", label: "call count" },
                    { key: "user_email_sent", label: "Email Sent Count" },
                ],
                rejected_fields: [
                    {
                        key: "index",
                        label: "Sr.",
                    },
                    { key: "id", label: "Registration ID" },
                    { key: "name", label: "Name" },
                    { key: "cnic", label: "CNIC" },
                    { key: "mobile", label: "Mobile" },
                    /* { key: "user_type", label: "User Type" },*/
                    { key: "created_at", label: "Registration Date" },
                    { key: "approved_at", label: "Rejected Date" },
                    { key: "approved_by", label: "Rejected By" },
                    /* { key: "is_approved", label: "Approval Status" },*/
                    { key: "reject_reason", label: "Rejected Reason", variant: 'info' },
                    //{ key: "user_calls", label: "call count" },
                    { key: "user_email_sent", label: "Email Sent Count" },
                ],
                type: null,
                rejected: false,
                pending: false,
                user_array: null,
            };
        },
        async mounted() {
            if (this.users && this.users.length > 0) {
                this.user_array = this.users;
                this.type = this.users[0].is_approved;
                if (this.type == '2') {
                    this.rejected = true;
                }
                else {
                    this.rejected = false;
                }
                if (this.type == '3') {
                    this.pending = true;
                }
                else {
                    this.pending = false;
                }
                try {
                } catch (error) {
                    this.displayError(error);
                }
            }
        },
        methods: {
            ...mapActions("appData", ["updateRank", "createUserCall", "getAdminFilteredDashboardData"]),
            async validationForm() {
                const success = await this.$refs.rankEditValidation.validate();
                if (success) {
                    await this.submit();
                }
            },
            async retreiveUsersData() {
                this.show = true;
                const _res = await this.getAdminFilteredDashboardData(this.obj_for_filtering);
                this.user_array = _res.data.data.users;
                this.show = false;
            },
            closeModal() {
                this.$nextTick(() => {
                    this.$bvModal.hide(this.id);
                });
                this.$emit("modalClosed");
            },
            async updateCallCount(user) {
                this.show = true;
                try {
                    // this.$swal({
                    //     title: "Did you make the call to " + user.name + "?",
                    //     icon: "warning",
                    //     showCancelButton: true,
                    //     confirmButtonText: "Yes, Update the Call Count!",
                    //     customClass: {
                    //         confirmButton: "btn btn-primary",
                    //         cancelButton: "btn btn-outline-primary ml-1",
                    //     },
                    //     buttonsStyling: false,
                    // }).then(async (result) => {
                    //     if (result.value) {
                    const resp = await this.createUserCall({
                        called_to: user.id,
                    });
                    if (resp.status === 200) {
                        await this.retreiveUsersData();
                        this.$swal({
                            title: "Call count updated successfully!",
                            icon: "success",
                            timer: 2500,
                        });
                    }
                    //     }
                    // });
                    this.show = false;
                } catch (error) {
                    this.show = false;
                    this.displayError(error);
                }
            },

            async submit() {
                try {
                    this.show = true;
                    const res = await this.updateRank({
                        name: this.name,
                        first_category: this.first_category.name,
                        pk: this.rank.id,
                    });
                    if (res.status === 200) {
                        this.$swal({
                            title: res.data.msg,
                            icon: "success",
                            timer: 1500
                        });
                        this.show = false;
                        await this.reset();
                        this.$nextTick(() => {
                            this.$bvModal.hide("view-filtered-user-modal");
                        });
                        this.$emit("modalClosed");
                    }
                } catch (error) {
                    this.show = false;
                    this.displayError(error);
                }
            },

            getTypeValue(value) {
                switch (value) {
                    case 1:
                        return "SERVING";
                    case 2:
                        return "RETIRED";
                    case 3:
                        return "CIVILIAN";
                }
            },
            reset() {
                this.name = null;
                this.strength = null;
            },
        },
        computed: {
            ...mapGetters({
                hasPermission: "appData/hasPermission",
                getUser: "appData/getUser",
            }),
        },
    };
</script>

<style>
    .vSelectStyle .vs__deselect {
        fill: #fff !important;
    }
</style>
