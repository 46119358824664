<template>
    <b-modal :id="id"
             title="Rank Edit Modal"
             centered
             hide-footer
             size="xl"
             @hidden="reset"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true">
        <template #modal-title>
            <h2 class="m-0">{{heading}}</h2>
        </template>
        <b-table responsive="sm"
                 :fields="fields"
                 :items="user_array"
                 :paginated="true"
                 details-td-class="m-0 p-0"
                 small>
            <template #cell(index)="row">
                {{row.index + 1}}.
            </template>
        </b-table>

        <b-overlay :show="show"
                   spinner-variant="primary"
                   no-wrap />
    </b-modal>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from "vee-validate";
    import { required, email } from "@validations";
    import util from "../../../util";
    import { mapActions, mapGetters } from "vuex";
    import Ripple from 'vue-ripple-directive'
    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        props: {
            users: Array,
            id: String,
            heading: String,
        },
        directives: {
            Ripple,
        },
        mixins: [util],
        data() {
            return {
                name: null,
                required,
                email,
                show: false,
                fields: [
                    {
                        key: "index",
                        label: "Sr.",
                    },
                    { key: "complainant.first_name", label: "Name" },
                    { key: "complainant.contact_no", label: "Mobile" },
                    { key: "complainant.marital_status_data.name", label: "Martial Status" },
                    { key: "complainant.serving_status_data.name", label: "Serving Status" },
                    { key: "relation_type_data.name", label: "Relation" },
                ],
                type: null,
                rejected: false,
                user_array: [],
            };
        },
        async mounted() {
            if (this.users && this.users.length > 0) {
                this.user_array = this.users;
            }
        },
        methods: {
            ...mapActions("appData", ["updateRank"]),
            async validationForm() {
                const success = await this.$refs.rankEditValidation.validate();
                if (success) {
                    await this.submit();
                }
            },
            reset() {
                this.name = null;
                this.strength = null;
            },
        },
        computed: {
            ...mapGetters({
            }),
        },
    };
</script>

<style>
    .vSelectStyle .vs__deselect {
        fill: #fff !important;
    }
</style>
